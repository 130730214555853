let timelineElements = [
  {
    id: 1,
    title: "Darshita Exim Pvt Ltd",
    description:
      "Darshita Exim Pvt. Ltd. is a Karnataka based Construction company registered at dated 10-12-2007.",
    date: "Dec 2007",
    icon: "work"
  },
  {
    id: 2,
    title: "Nestron Global Trading Limited",
    description:
      "Nestron is a modern housing entity providing alternative housing solutions for the mass public.",
    date: "Mar 2016",
    icon: "work"
  }];

export default timelineElements;
