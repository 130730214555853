export const localRoute = {
  home: '/',

  contact: '/contact-us',
  career: '/careers',
  about: '/about-us',
  csr: '/csr',
  news: '/news',
  career: '/career',

  services: '/services',

  blogs: '/blogs',
  blogDetail: '/blog',

  news: '/news',
  newsDetail: '/news-detail',

  realEstate: '/real-estate',
  angelFunding: '/angel-funding',
  coworking: '/coworking',
  education: '/education',
  trading: '/trading',

  notFound: '*',
  notFound500: '*',
}
