import { alpha, makeStyles } from '@material-ui/core/styles';
export const styles = (theme) => ({
    pageTitle: {
        color: "#602994",
        fontWeight: "400",
    },
    root: {
        flexGrow: 1,
        backgroundColor: "transparent",
        boxShadow: 'none',
        '&& .MuiBox-root': {
            padding: '0px !important',
        },

    },

    stepperBg: {
        background: 'none',
        padding: 0,
        margin: 0,
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: 0,
            fontSize: 9,
            color: '#777',
        }
    },


    tabColor: {
        color: 'red',
        '&& .Mui-selected': {
            color: '#4EAA23',
            fontSize: '25px',
            fontWeight: '700'
        },

        '&& .MuiTabs-indicator': {
            background: '#4EAA23',
            width: '5px',
            borderRadius: '5px'
        },
        '&& .MuiButtonBase-root-MuiTab-root': {
            fontSize: '40px'
        }
    },

    tabSize: {
        '&& .MuiButtonBase-root-MuiTab-root': {
            fontSize: '40px'
        }
    },

});