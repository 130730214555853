let timelineElements = [
  {
    id: 1,
    title: "315 WORK AVENUE SPACES PRIVATE LIMITED",
    description:
      "315Work Avenue is here to help you with premium and flexible co working space provider",
    date: "2011",
    icon: "work"
  },
  {
    id: 2,
    title: "315 Work Avenue HYD Spaces Private Limited",
    description: "315Work Avenue is here to help you with premium and flexible co working space provider in Hyderabad",
    date: "2019",
    icon: "work"
  },
  {
    id: 3,
    title: "315 Work Avenue Pune Spaces Private Limited",
    description: "315Work Avenue is here to help you with premium and flexible co working space provider in Pune",
    date: "2019",
    icon: "work"
  },
  {
    id: 4,
    title: "315 Work Avenue Mumbai Spaces Private Limited",
    description: "315Work Avenue is here to help you with premium and flexible co working space provider in Mumbai",
    date: "2021",
    icon: "work"
  },
  {
    id: 5,
    title: "315 Work Avenue Asia PTE. Limited",
    description: "315Work Avenue is here to help you with premium and flexible co working space provider in Asia",
    date: "2021",
    icon: "work"
  },
  {
    id: 6,
    title: "315 Work Avenue Facilities LLP",
    description: "315Work Avenue is here to help you with premium and flexible co working space provider in India",
    date: "2021",
    icon: "work"
  },];

export default timelineElements;
