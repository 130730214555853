import React from "react";
import { useHistory } from "react-router-dom";
import { localRoute } from "routes/localRoutes";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";

export default function News() {
  const history = useHistory();
  const handleBackContact = () => {
    history.push(localRoute.contact);
    // navRef.current.classList.toggle("show");
  };

  const HandleGoNews = () => {
    history.push(localRoute.news);
  };
  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, #141212 0%, rgba(20, 18, 18, 0.8) 100%)",
      }}
    >
      <div class="row" style={{ alignItems: "center" }}>
        <div
          class="col-lg-4"
          data-aos-duration="500"
          style={{ padding: "60px" }}
        >
          <span class="fs-40 mt-10 fw-900 font-montserrat cl-white">
            Have an Idea?
          </span>
          <p
            class="font-montserrat fw-400 fs-22 mb-20"
            style={{ opacity: "0.8", color: "#fff" }}
          >
            Share this innovative idea with us
          </p>
          <Button
            onClick={handleBackContact}
            class="font-montserrat"
            size="large"
            style={{
              background: "#fff",
              color: "#000",
              fontWeight: "700",
              padding: "10px 25px 10px 25px",
            }}
          >
            Let's Have a Coffee
          </Button>
        </div>

        <div
          class="col-lg-4"
          data-aos-duration="500"
          style={{ textAlign: "center" }}
        >
          <img
            src="assets/home/howwework.png"
            alt=""
            data-aos="zoom-in-up"
            style={{ filter: "brightness(0.3)" }}
          />
        </div>

        <div
          class="col-lg-4"
          data-aos-duration="500"
          style={{ textAlign: "center" }}
        >
          <span
            class="cl-white fs-22 font-montserrat fw-200"
            style={{ padding: "60px", textAlign: "left" }}
          >
            NestaVera, a venture of veterans in the field of business and
            investing with over 60 years of collective experience
          </span>
        </div>
      </div>
    </div>
  );
}
