import React from "react";
import { useHistory } from "react-router-dom";
import { localRoute } from "../../routes/localRoutes";

export default function Footer() {
  const history = useHistory();

  const handleBackHome = () => {
    history.push(localRoute.home);
  };
  const handleBackServices = () => {
    // history.push(localRoute.services)
    window.location.replace("/#services");
  };

  const handleBackWhyUs = () => {
    window.location.replace("/#why-us");
  };

  const handleBackAbout = () => {
    history.push(localRoute.about);
  };

  const handleBackBlogs = () => {
    history.push(localRoute.blogs);
  };

  const handleBackCSR = () => {
    history.push(localRoute.csr);
  };

  const handleBackCareer = () => {
    history.push(localRoute.career);
  };

  const handleBackRealEstate = () => {
    history.push(localRoute.realEstate);
  };

  const handleBackEducation = () => {
    history.push(localRoute.education);
  };

  const handleBackTrading = () => {
    history.push(localRoute.trading);
  };

  const handleBackAngelFunding = () => {
    history.push(localRoute.angelFunding);
  };

  const handleBackCoworking = () => {
    history.push(localRoute.coworking);
  };

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };

  return (
    <>
      <footer
        class="theme-footer-two pt-50 md-pt-40"
        style={{ backgroundColor: "#000" }}
      >
        <div class="top-footer mt-20 pb-10">
          <div class="container">
            <div class="row justify-content-between">
              <div class="font-montserrat col-lg-2 col-sm-4 col-xs-12 footer-list">
                <h5
                  class="footer-title font-montserrat fw-600"
                  style={{ color: "#A7A3FF", fontSize: "20px" }}
                >
                  Company
                </h5>
                <ul>
                  <li>
                    <a
                      id="footerIcon"
                      href="home.html"
                      style={{ cursor: "pointer" }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackAbout}
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackBlogs}
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackCSR}
                    >
                      CSR
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackCareer}
                    >
                      Career
                    </a>
                  </li>
                </ul>
              </div>

              <div class="font-montserrat col-lg-2 col-sm-4 col-xs-12 footer-list">
                <h5
                  class="footer-title font-montserrat fw-600"
                  style={{ color: "#A7A3FF", fontSize: "20px" }}
                >
                  Services
                </h5>
                <ul>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackRealEstate}
                    >
                      Real-Estate
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackEducation}
                    >
                      Education
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackCoworking}
                    >
                      Coworking
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackAngelFunding}
                    >
                      Angel Funding
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackTrading}
                    >
                      Trading
                    </a>
                  </li>
                </ul>
              </div>

              <div class="font-montserrat col-lg-3 col-sm-4 col-xs-12 footer-list">
                <h5
                  class="footer-title font-montserrat fw-600"
                  style={{ color: "#A7A3FF", fontSize: "20px" }}
                >
                  Help
                </h5>
                <ul>
                  <li>
                    <a
                      id="footerIcon"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackContact}
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      target="_blank"
                      href="mailto:contact@nestavera.com"
                      style={{ cursor: "pointer" }}
                    >
                      contact@nestavera.com
                    </a>
                  </li>
                  <li>
                    <a
                      id="footerIcon"
                      target="_blank"
                      href="mailto:business@nestavera.com"
                      data-rel="external"
                      style={{ cursor: "pointer" }}
                    >
                      business@nestavera.com
                    </a>
                  </li>
                  {/* <li><a id='footerIcon' target="_blank" href="tel:++918040327000" data-rel="external" style={{ cursor: "pointer" }}>+91 80403 27000</a></li> */}
                </ul>
              </div>

              {/* <div class="font-montserrat col-lg-2 col-md-2 col-sm-6 footer-list">
                                <h5 class="footer-title font-montserrat fw-600" style={{ color: '#A7A3FF', fontSize: '20px' }}>Follow us</h5>
                                <ul class="social-icon">
                                    <li><a style={{ color: '#fff' }} target="_blank" href=""><i class="fa fa-facebook"></i></a></li>
                                    <li><a id='footerIcon' style={{ fontSize: '24px' }} target="_blank" href="https://www.linkedin.com/company/nesta-vera-group"><i class="fa fa-linkedin"></i><span style={{ fontSize: '18px' }}>&nbsp;&nbsp;LinkedIn</span></a></li>
                                    <li><a id='footerIcon' style={{ fontSize: '24px' }} target="_blank" href="https://www.linkedin.com/company/nesta-vera-group"><i class="fa fa-instagram"></i><span style={{ fontSize: '18px' }}>&nbsp;&nbsp;Instagram</span></a></li>
                                    <li><a style={{ color: '#fff' }} target="_blank" href=""><i class="fa fa-twitter"></i></a></li>
                                    <li><a style={{ color: '#fff' }} target="_blank" href=""><i class="fa fa-youtube"></i></a></li>
                                </ul>
                            </div> */}

              <div class="font-montserrat fw-500 col-lg-5 col-sm-12 col-xs-12 address-list">
                <a
                  style={{ cursor: "pointer", color: "#fff" }}
                  href="home.html"
                  class="logo"
                >
                  <img
                    style={{ height: "60px" }}
                    // src="../../../../assets/home/Nestavera.png"
                    src="../../../../assets/home/NV_LOGO.png"
                    alt="nestavera-logo"
                  />
                </a>

                <ul class="mt-10 info">
                  <li>
                    <a
                      target="_blank"
                      href="https://goo.gl/maps/CMe6HiKu67j1NuGE7"
                      style={{ color: "#fff", fontWeight: 600 }}
                    >
                      <i class="fa fa-map"></i> &nbsp;2nd & 3rd Floor, # 252,
                      8th Main Road, Amarajyothi Layout, Domlur (Off. Embassy
                      Golf Link Road),Bangalore 560 071
                    </a>
                  </li>
                  {/* <hr style={{ marginTop: "5px", background: '#fff' }} /> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="bottom-footer-content">
            <div class="d-flex align-items-center justify-content-center">
              <p
                style={{ borderTop: "1.5px solid #fff", color: "#fff" }}
                class="font-montserrat pt-10 fw-500 "
              >
                Copyright &copy; 2022-23 All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
