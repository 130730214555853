import React from "react";
import { useHistory } from "react-router-dom";
import { localRoute } from "routes/localRoutes";
import DoneIcon from "@mui/icons-material/Done";
import "./BusinessProposalCss.css";

export default function BusinessProposal() {
  const history = useHistory();
  const handleBackContact = () => {
    history.push(localRoute.contact);
    // navRef.current.classList.toggle("show");
  };

  const HandleGoNews = () => {
    history.push(localRoute.news);
  };
  return (
    <div>
      <div class="font-montserrat block-style-sixteen">
        <h2 class="font-montserrat fs-50 text-center">
          Grow with <br />
          our strategic
          <br />
          <span style={{ color: "#aaa" }}>partnership</span>
        </h2>
        <div class="mt-60 col-lg-10 container">
          <div class="smartphone">
            <div class="content">
              <div style={{ overflow: "auto", maxHeight: "480px" }}>
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                    boxShadow:
                      "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <div class="container" style={{ backgroundColor: "#171515" }}>
                    <div style={{ padding: "120px 0px 170px" }}>
                      <h2
                        data-aos="zoom-in"
                        data-aos-duration="3000"
                        class="fs-80 font-montserrat fw-800"
                        style={{ color: " #D9D9D9", textAlign: "center" }}
                      >
                        Semper <br />
                        Fidelis
                      </h2>
                      <h2
                        class="fs-20 font-montserrat fw-700"
                        style={{ color: "#D9D9D9", textAlign: "center" }}
                      >
                        – A promise to live by
                      </h2>
                    </div>

                    <div class="text-center mb-130">
                      <img
                        style={{ height: "80px", margin: "auto" }}
                        src="../../../../assets/home/NV_LOGO.png"
                        alt="nestavera-logo"
                      />

                      <h3
                        class="mt-50 fs-40 font-montserrat fw-700"
                        style={{ color: "#D9D9D9", textAlign: "center" }}
                      >
                        Nesta - Means Pure
                      </h3>
                      <h3
                        class="fs-40 font-montserrat fw-700"
                        style={{ color: "#D9D9D9", textAlign: "center" }}
                      >
                        Vera - Means Faith
                      </h3>
                    </div>

                    <div class="text-center pb-100">
                      <h2
                        class="font-montserrat fs-40 fw-700 mt-10"
                        style={{ color: "#FFF" }}
                      >
                        <span style={{ color: "#2F94CC" }}>FAITHFUL</span>
                        &nbsp; & &nbsp;
                        <span style={{ color: "#5EBA3F" }}>LOYAL</span>
                      </h2>
                      <span
                        class="font-montserrat mt-30 fs-18"
                        style={{
                          color: "#fff",
                          textAlign: "left",
                          padding: "0px 20px 0px 30px ",
                        }}
                      >
                        Our Logo is the bird, Goose, which is represented by the
                        Latin word “Semper Fidelis”, which means “always
                        faithful” and “always loyal”
                      </span>
                      <span
                        class="font-montserrat mt-20 fs-18"
                        style={{
                          color: "#fff",
                          textAlign: "left",
                          padding: "0px 20px 0px 30px ",
                        }}
                      >
                        We have chosen the Goose, to represent us, as its
                        symbolic attributes of, loyalty, teamwork, confidence,
                        protection, determination, courage, inspiration,
                        guidance and stability, along with its ability to soar
                        to greater heights, are in accordance with our purpose.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
