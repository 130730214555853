import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import TimeChart from "./TimeChart/TimeChart";
import SubFooter from "components/subFooter";
import Gallery from "components/about/Gallery";

export default function Trading() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img data-aos="zoom-in-up" src="assets/service/trade.jpg" alt="" />
          <div class="font-montserrat sliderBanner">
            <p
              class="font-montserrat mb-40 fw-200 fs-30"
              style={{ textAlign: "center" }}
            >
              RECOGNISING THE BEST OF STOCKS THAT ALWAYS OUTPERFORM
            </p>
            <p
              class="font-montserrat fs-25 fw-300"
              style={{ textAlign: "center" }}
            >
              INDIA &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; SINGAPORE
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; DUBAI
            </p>
          </div>
        </div>

        <div class="container">
          <div class="row pt-90 pb-90 sm-pt-30">
            <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
              Trading
            </div>

            <div class="col-lg-3 ml-auto order-lg-first mt-30">
              <span class="fw-600" style={{ color: "#1C1C1C" }}>
                — — Trading at its best
              </span>
              <h2
                style={{ cursor: "pointer", color: "#1C1C1C" }}
                class="m-auto fw-800 fs-50 font-montserrat"
              >
                Get the NestaVera Edge on Trading
              </h2>
            </div>
            <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
              <div class="text-wrapper">
                <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                  With an eye for the best-performing stocks, Mr. Manas Mehrotra
                  brings his experience in the field that helps us stay ahead in
                  the trading game. His strategy of investing in
                  well-diversified stocks with long-term growth potential is our
                  guiding principle.
                </p>
                <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                  Mr. Manas is also a Director at Darshita Exim - a company
                  actively involved in the International Trading business. In a
                  short span of 3 years, the company has achieved various
                  milestones. The company is well recognised in the
                  International trade business. The company is well diversified
                  with exposures in China, Hong Kong, Singapore, Dubai and USA."
                </p>
              </div>
            </div>
          </div>
        </div>
{/* 
        <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/india.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                INDIA
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                One of the leading firms providing actionable insights on stocks
                in India.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 order-lg-last order-md-last row-padd0">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/singapore.png"
                alt=""
                style={{ filter: "brightness(0.3)", padding: "0px" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                SINGAPORE
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                A well-known name in the stock market industry that are
                providing the best of stocks that always outperform in Singapore
                for years.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/dubai.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                DUBAI
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Backed by a team of experienced analysts tracking the
                performance of stocks in Dubai and help to make better
                investment decisions.
              </p>
            </div>
          </div>
        </div> */}
        
        <Gallery />

        <TimeChart />

        <SubFooter />
      </div>
    </>
  );
}
