import React from 'react'
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { localRoute } from 'routes/localRoutes';

export default function BusinessGrowth() {
  const history = useHistory();

  const handleBackContact = () => {
    history.push(localRoute.contact);
    // if(currentRoute==)
  }
  return (


    <div class="container" >
      <div data-aos="fade-up" data-aos-easing="linear" data-aos-anchor-placement="top-center" data-aos-duration="1000" class="row mt-50 mb-50">
        <div class="col-lg-8 m-auto" >
          <h1 class="hero-heading text-center font-montserrat fs-50 fw-700">Making everything around you
            easier to build and <span style={{ color: '#4EAA23' }}>grow!</span></h1>
        </div>
        <div style={{ textAlign: "center" }} class="container">
          <span class="col-lg-8 mt-20 mb-20 font-montserrat fs-22 fw-400" style={{ padding: '30px', color: '#001B2F' }}>
            Established with the aim of accelerating growth of businesses
            with great potential delivering returns to investors
            through long-term compound growth.
          </span>
          <br />
          <Button onClick={handleBackContact} variant="" size="large" style={{ borderRadius: '30px', color: '#000', background: '#fff', border: '1px solid #000' }}>Contact Us</Button>
          <Button variant="" size="large" style={{ borderRadius: '30px', marginLeft: '10px', color: '#fff', background: '#5EBA3F', border: '1px solid #5EBA3F' }}>Write to Us</Button>
        </div>
      </div>
      <div data-aos="flip-down" data-aos-easing="linear" data-aos-anchor-placement="top-center" data-aos-duration="1000" class="col-lg-8 m-auto">
        <img src="assets/home/businessgrowth.png" alt="Business Growth Banner" class="illustration" />
      </div>
    </div >


  )
}

