import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AngelFundingStyle";
import AngelFunding from '../../components/angelFunding'
const AngelFundingPage = React.memo((props) => {
  return (
    <>
      <AngelFunding />
    </>
  );
});

export default withRouter(AngelFundingPage);
