import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./BlogDetailsStyle";
import BlogDetails from '../../../components/blog/blogDetails'
const BlogDetailsPage = React.memo((props) => {
  return (
    <>
      <BlogDetails />
    </>
  );
});

export default withRouter(BlogDetailsPage);
