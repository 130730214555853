import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../context/GlobalState";
import parse from "html-react-parser";
import "./LatestBlogStyle.css";
import { useParams, useHistory } from "react-router-dom";
import { localRoute } from "../../../routes/localRoutes";
// import { getBlog } from '../../../context/actions/blogAction/getBlog'
// import { getBlogs } from "../../../context/actions/blogAction/getBlogs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "../../../axios";
import BlogCard from "components/blog/blogCard/BlogCard";

export default function BlogDetails() {
  const [blogItemsList, setBlogItemsList] = useState([]);
  const [selectedBlogId, setSelectedBlogId] = useState(0);

  const history = useHistory();
  const { blog_id } = useParams();

  let pageTitlePath = history.location && history.location.pathname;

  let newpath = "https://taskmo.com" + pageTitlePath;

  console.log("newpath", newpath);

  const OpenBlog = (blog_id) => {
    setSelectedBlogId(blog_id);
    history.push(`${localRoute.blogDetail}/${blog_id}`);
  };

  useEffect(() => {
    axios
      .get("/articles/category/blog/?offset=0&limit=2")
      .then((res) => {
        setBlogItemsList(res.data.results);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const handleGoBlogs = () => {
    history.push(`${localRoute.blogs}`);
  };

  return (
    <div>


      <section style={{ paddingTop: "0px" }} class="blog-area section-gap">
        <div class="font-montserrat feature-blog-one blog-page-bg container">
          <div class="section-heading mb-50">
            <h2 class="font-montserrat fs-50 fw-700 text-center">
              Our <span style={{ color: "#FD6A5E" }}>Blogs</span>
            </h2>
          </div>

          <div class="container">
            <div class="row">
              {blogItemsList && blogItemsList.map((blog) => (
                <BlogCard key={blog.id} blog={blog} openBlog={() => OpenBlog(blog.id)} />
              ))}
              <div
                class="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div
                  class="post-meta"
                  style={{ background: "#1C1C1C", textAlign: "center" }}
                >
                  <h3 style={{ paddingTop: "150px" }}>
                    <a
                      style={{ color: "#fff" }}
                      class="font-montserrat title fw-600"
                    >
                      Blogs & Articles with New updates
                    </a>
                  </h3>
                  <Button
                    variant="contained"
                    style={{ background: "#fff", color: "#000", marginTop: 20 }}
                    onClick={handleGoBlogs}
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Discover All
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img class="green-line" src="assets/home/line2.png" alt="" style={{ margin: 'auto' }} /> */}
        {/* <img class="green-line" src="assets/home/line2.png" alt="" style={{ margin: 'auto' }} data-aos="flip-down" data-aos-duration="2000" /> */}
      </section>
      {/* <!--====== Blog Area End ======--> */}
    </div>
  );
}
