import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./EducationStyle";
import Education from '../../components/education'
const EducationPage = React.memo((props) => {
  return (
    <>
      <Education />
    </>
  );
});

export default withRouter(EducationPage);
