import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./TradingStyle";
import Trading from '../../components/trading'
const TradingPage = React.memo((props) => {
  return (
    <>
      <Trading />
    </>
  );
});

export default withRouter(TradingPage);
