import React from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import TimeChart from "./TimeChart/TimeChart";
import SubFooter from "components/subFooter";
import Gallery from "components/about/Gallery";

export default function Coworking() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img
            data-aos="zoom-in-up"
            src="assets/service/coworking.jpg"
            alt=""
          />
          <div class="font-montserrat sliderBanner">
            <p
              class="font-montserrat mb-40 fw-200 fs-30"
              style={{ textAlign: "center" }}
            >
              ESTABLISHING COWORKING SPACES THAT ENABLE THE LEADERS OF TOMORROW
            </p>
            <p
              class="font-montserrat fs-25 fw-300"
              style={{ textAlign: "center" }}
            >
              INDIA &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; SINGAPORE
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; DUBAI
            </p>
          </div>
        </div>

        <div>
          <div class="container">
            <div class="row pt-90 pb-90 sm-pt-30">
              <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
                Coworking
              </div>

              <div class="col-lg-3 ml-auto order-lg-first mt-30">
                <span class="fw-600" style={{ color: "#1C1C1C" }}>
                  — — CoWorking at its best
                </span>
                <h2
                  style={{ cursor: "pointer", color: "#1C1C1C" }}
                  class="m-auto fw-800 fs-50 font-montserrat"
                >
                  Get the NestaVera Edge on CoWorking
                </h2>
              </div>
              <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
                <div class="text-wrapper">
                  <p class="fw-600 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    “The Spirit of Coworking allows to find Coworkers who are
                    worth working with”- Cynthia Chiam
                  </p>
                  <p class="fw-400 fs-16 mt-10" style={{ color: "#1C1C1C" }}>
                    Coworking gives you the benefit of a full-time office, but
                    you only pay for the number of desks you use. Choose your
                    own permanent desk or sit wherever you'd like each day
                    through a hot-desking setup, available on a first come,
                    first served basis. IncubexNestaVera provides an environment
                    for those who work alone but are looking for a productive
                    office with like-minded people to interact with. Coworking
                    is Excellent for Consultants, Trainers, Startup-Founders,
                    Sales, professionals and Entrepreneurs. IncubexNestaVeraaims
                    at creating a Coworking dimensions thriving for a brighter
                    future and better society. We provide new dimension towards
                    office spaces and also a proven cost effective way with an
                    advantage of surrounding yourself with people who can help
                    you with your business and give your company new direction
                    towards success.
                  </p>
                  <p class="fw-600 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    Benefits to the members:
                  </p>
                  <p class="fw-400 fs-16 " style={{ color: "#1C1C1C" }}>
                    Becoming the member of IncubexNestaVerawill boost your
                    business. As a valued member you get access to credible
                    professional service providers across legal, finance, HR,
                    marketing, and more. We provide exclusive offers and
                    packages for everything that touches the life..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/india.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                INDIA
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Building coworking spaces in India, home to many thriving
                startups, and making it easier for people to collaborate,
                connect and create.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 order-lg-last order-md-last row-padd0">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/singapore.png"
                alt=""
                style={{ filter: "brightness(0.3)", padding: "0px" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                SINGAPORE
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Building a better future in Singapore by providing affordable
                and convenient workspace to our future leaders.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/dubai.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                DUBAI
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Creating spaces for many people to start and grow their business
                and providing a supportive community for entrepreneurs in Dubai.
              </p>
            </div>
          </div>
        </div> */}

        <Gallery />
        
        <TimeChart />

        <SubFooter />
      </div>
    </>
  );
}
