import React from 'react'

export default function OurPartners() {

    return (
        <>

            {/* <div class="container ">
                <div class="row justify-content-center">
                    <img src="assets/home/clients.png" alt="" style={{ width: '70%' }} />
                    <div class='col-lg-8 m-auto text-center'>
                        <div class="title-style-four">
                            <p class="font-montserrat fw-400 mt-200 fs-50" style={{ color: '#8F8F8F', lineHeight: '1.2' }}>
                                NestaVera have invested in over 500+
                                Business join to make your growth
                            </p>
                            <div class="mt-150 row justify-content-around" >
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="https://via.placeholder.com/150x50" alt="" /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="https://via.placeholder.com/150x50" alt="" /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="https://via.placeholder.com/150x50" alt="" /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="https://via.placeholder.com/150x50" alt="" /></div>
                                </div>

                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                </div>
                                <div class="" style={{ padding: '10px' }}>
                                    <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="green-line" src="assets/home/line.png" alt="" style={{ margin: 'auto' }} />
            </div> */}



            <div class="container">
                <img src="assets/home/clients.png" alt="" class="illustration" style={{ width: '80%', margin: 'auto' }} />
                <div class="" style={{ marginTop: '-750px' }}>
                    <div class="row">
                        <div class='pb-100 col-lg-9 m-auto text-center'>
                            <div class="title-style-four">
                                <p class="font-montserrat fw-400 mt-50 fs-40" style={{ color: '#8F8F8F', lineHeight: '1.2' }} data-aos="zoom-in" data-aos-duration="2000" >
                                    NestaVera have invested in over 500+
                                    Business join to make your growth
                                </p>
                                <div class="mt-100 row justify-content-around" style={{ marginLeft: '-180px' }}>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/1.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/2.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/4.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    {/* <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/3.png" alt="" style={{ width: '100px' }} /></div>
                                    </div> */}

                                </div>

                                <div class="mt-50 mb-100 row justify-content-around" style={{ marginRight: '-180px' }}>

                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/5.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/6.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/7.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                    <div class="" style={{ padding: '10px' }}>
                                        <div class=" d-flex align-items-center "><img src="assets/home/partners/8.png" alt="" style={{ width: '100px' }} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img class="green-line" src="assets/home/line.png" alt="" style={{ margin: 'auto' }} data-aos="flip-up" data-aos-duration="2000" />
        </>
    );
}
