let timelineElements = [
  {
    id: 1,
    title: "Hareshwar Trading Pvt Ltd",
    description:
      "Hareshwar Trading Private Limited is a Non-govt company, incorporated on 20 Nov, 2000. It's a private unlisted company and is classified as'company limited by shares'.",
    date: "Nov 2000",
    icon: "work"
  },
  {
    id: 2,
    title: "MSR Infotech Pvt Ltd",
    description: "MSR InfoTech is a well-known through the means of providing dedicated Accounting Software Solutions connectivity to Tally ERP 9.",
    date: "Jan 2005",
    icon: "work"
  },
  {
    id: 3,
    title: "VIBGYOR KNOWLEDGE LEARNING PRIVATE LIMITED",
    description: "Vibgyor Knowledge Learning Private Limited is majorly in Community, personal & Social Services business from last 13 years",
    date: "Mar 2009",
    icon: "work"
  },
  {
    id: 4,
    title: "WATER EDGE BUILDERS PRIVATE LIMITED",
    description: "Water Edge Builders Private Limited provides construction services. SECTOR. Industrials. INDUSTRY. Industrial Services. SUB-INDUSTRY. Engineering & Construction .",
    date: "Dec 2009",
    icon: "work"
  },
  {
    id: 5,
    title: "GREENWOODS SPARKING EDUCATION PRIVATE LIMITED",
    description: "Greenwood High is one of the National Award Winning Best International Schools in Bangalore, Karnataka",
    date: "Mar 2011",
    icon: "work"
  },
  {
    id: 6,
    title: "NURTURE EDUCATIONAL SERVICES PRIVATE LIMITED",
    description: "Nurture Educational Services Private Limited is majorly in Community, personal & Social Services business from last 11 years ",
    date: "Jul 2011",
    icon: "work"
  },
  {
    id: 7,
    title: "NINE HILLS EDUCATION PRIVATE LIMITED",
    description: "Nine Hills Education Private Limited is involved in Community, Personal & Social Services Activity",
    date: "Feb 2013",
    icon: "work"
  },
  {
    id: 8,
    title: "NEELANCHAL SKYSKAPE PRIVATE LIMITED",
    description: "Building of complete constructions or parts thereof civil engineering",
    date: "Oct 2014",
    icon: "work"
  },
  {
    id: 9,
    title: "Zolo Stays",
    description: "Zolostays is India's largest co-living platform that provides easy, delightful, and beautiful stays",
    date: "Jan 2016",
    icon: "work"
  },
  {
    id: 10,
    title: "Neem Groove",
    description: "Neem Grove Llp is a 4 years 5 months old Limited Liability Partnership incorporated on 07 Jun 2018.",
    date: "July 2018",
    icon: "work"
  },
  {
    id: 11,
    title: "Novashine LLP",
    description: "Nova Shine Estates Llp is a Limited Liability Partnership firm incorporated on 11 October 2018",
    date: "Nov 2018",
    icon: "work"
  }];

export default timelineElements;
