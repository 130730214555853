import React, { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useHistory } from "react-router-dom";
import { localRoute } from "routes/localRoutes";
import axios from "../../axios";
import Career_Form from "components/Career_Form";
import moment from "moment";

export default function About() {
  const [value, setValue] = React.useState("female");
  const [location, setLocation] = React.useState("");
  const [allTypes, setAllTypes] = React.useState("");
  const [careers, setCareers] = useState([]);
  const [count, setCount] = useState();
  const [next, setNext] = useState();
  const [totalPages, setTotalPages] = useState();
  const [categories, setCategories] = useState();
  const [locations, setLocations] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleChangeType = (event) => {
    setAllTypes(event.target.value);
  };

  const history = useHistory();

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };

  useEffect(() => {
    axios
      .get(`/careers/`)
      .then((res) => {
        const pageCount = Math.ceil(res.data.count / 10);
        setTotalPages(pageCount);
        setCareers(res.data.results);
        setNext(res.data.next);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.error(e);
      });

    axios
      .get(`/careers-categories/`)
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((e) => {
        console.error(e);
      });

    axios
      .get(`/careers-locations/`)
      .then((res) => {
        setLocations(res.data.results);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);


  const updateCareersWithLocation = (location) => {
    setSelectedLocation(location);
    updateCareersWithCategoryAndLocation();
    // axios
    //   .get(`/careers/?location=${location}`)
    //   .then((res) => {
    //     const pageCount = Math.ceil(res.data.count / 10);
    //     setTotalPages(pageCount);
    //     setCareers(res.data.results);
    //     setNext(res.data.next);
    //     setCount(res.data.count);
    //   }
    //   )
    //   .catch((e) => {
    //     console.error(e);
    //   }
    // );
  }

  const updateCareersWithCategory = (category) => {
    setSelectedCategory(category);
    updateCareersWithCategoryAndLocation();
    // axios
    //   .get(`/careers/?category=${category}`)
    //   .then((res) => {
    //     const pageCount = Math.ceil(res.data.count / 10);
    //     setTotalPages(pageCount);
    //     setCareers(res.data.results);
    //     setNext(res.data.next);
    //     setCount(res.data.count);
    //     setSelectedCategory(category);
    //   }
    //   )
    //   .catch((e) => {
    //     console.error(e);
    //   }
    // );
  }

  const updateCareersWithCategoryAndLocation = () => {
    if (selectedCategory && selectedLocation) {
      axios
        .get(`/careers/?category=${selectedCategory}&location=${selectedLocation}`)
        .then((res) => {
          const pageCount = Math.ceil(res.data.count / 10);
          setTotalPages(pageCount);
          setCareers(res.data.results);
          setNext(res.data.next);
          setCount(res.data.count);
        }
        )
        .catch((e) => {
          console.error(e);
        }
        );
    } else if (selectedCategory) {
      axios
        .get(`/careers/?category=${selectedCategory}`)
        .then((res) => {
          const pageCount = Math.ceil(res.data.count / 10);
          setTotalPages(pageCount);
          setCareers(res.data.results);
          setNext(res.data.next);
          setCount(res.data.count);
        }
        )
        .catch((e) => {
          console.error(e);
        }
        );
    } else if (selectedLocation) {
      axios
        .get(`/careers/?location=${selectedLocation}`)
        .then((res) => {
          const pageCount = Math.ceil(res.data.count / 10);
          setTotalPages(pageCount);
          setCareers(res.data.results);
          setNext(res.data.next);
          setCount(res.data.count);
        }
        )
        .catch((e) => {
          console.error(e);
        }
        );
    }
  }


  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera- Career</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>
        <div class="carrerBanner">
          <img data-aos="zoom-in-up" src="assets/career/main.jpg" alt=""/>
        </div>

        <div class="blog-page-white-bg blog-v3 pt-100">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="blog-sidebar-one">
                  <div class="mb-20 ">
                    <h4
                      class="sidebar-title font-montserrat fw-700"
                      style={{ fontSize: "24px" }}
                    >
                      Filters
                    </h4>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                      >
                        {categories &&
                          categories.map((item) => (
                            <FormControlLabel
                              value={item.id}
                              control={
                              <Radio
                                color="default"
                                style={{ color: "#000" }}
                                checked={selectedCategory === item.id}
                              />
                              }
                              label={item.title}
                              key={item.id}
                              onClick={() => updateCareersWithCategory(item.id)}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div class="mb-20 ">
                    <h4
                      class="sidebar-title font-montserrat fw-700"
                      style={{ fontSize: "24px" }}
                    >
                      Location
                    </h4>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Location
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={location}
                        label="Location"
                        onChange={handleChangeLocation}
                      >
                        {locations &&
                          locations.map((location) => (
                            <MenuItem 
                              value={location.id}
                              onClick={() => updateCareersWithLocation(location.id)}
                              >
                              {location.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* <div class="mb-20 ">
                                        <h4 class="sidebar-title font-montserrat fw-700" style={{ fontSize: '24px' }}>Type of Work</h4>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">All Types</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={allTypes}
                                                label="All Types"
                                                onChange={handleChangeType}
                                            >
                                                { locations && locations.map(item => (
                                                    <MenuItem value={item.id}>{item.title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> */}
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row">
                  {careers &&
                    careers.map((career) => (
                      <div
                        class="col-lg-6 col-md-6 mb-30"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <div class="post-meta">
                          <h3>
                            <a
                              style={{ cursor: "pointer", color: "#aaa" }}
                              class="font-montserrat title fw-600 pb-10"
                            >
                              {career.title}
                            </a>
                          </h3>
                          <h2 class="fs-20 font-montserrat pt-2">
                            {career.body}
                          </h2>
                          <div class="font-montserrat tag pt-1 pb-1">
                            {moment(career.updated_at).format("MMMM Do YYYY")}
                          </div>
                          <Career_Form />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="fancy-short-banner-three mt-30 mb-30">
          <div class="container">
            <div
              class="bg-wrapper howitworks"
              style={{ background: "#1C1C1C" }}
            >
              <div class="col-lg-12">
                <div
                  class="form-wrapper"
                  style={{ textAlign: "center", marginBottom: "30px" }}
                >
                  <h2
                    class="font-montserrat"
                    style={{ color: "#fff", fontSize: "40px" }}
                  >
                    Let's find the right invest for your business
                  </h2>
                </div>
                {/* <div class="form-wrapper" style={{ padding: '0px', textAlign: 'center' }}>
                                    <Button size="large" style={{ background: '#fff', color: '#000', fontWeight: '600', padding: '10px 25px 10px 25px' }} onClick={handleBackContact}>
                                        Explore Now
                                    </Button>
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}
