import React from 'react'
import { useHistory } from "react-router-dom";
import { localRoute } from 'routes/localRoutes';
import DoneIcon from '@mui/icons-material/Done';

export default function News() {
    const history = useHistory();
    const handleBackContact = () => {
        history.push(localRoute.contact);
        // navRef.current.classList.toggle("show");
    };

    const HandleGoNews = () => {
        history.push(localRoute.news);
    }
    return (
        <div>
            <div class="font-montserrat block-style-sixteen">
                <div class='container'>
                    <div class="row" style={{ alignItems: 'center' }}>
                        <div class="col-xl-6 col-lg-6" data-aos="fade-right" data-aos-duration="500" >
                            <div class="flexbox1">
                                <div class="text-center">
                                    <h1 class="fs-50 mt-10 mb-20 fw-900 font-montserrat">In The News</h1>
                                </div>
                            </div>
                            <p style={{ paddingBottom: '30px', color: '#000', fontSize: '25px' }} class=" mt-10 fw-900 font-montserrat">Co-working firms expect GST relief</p>
                            <p class='fw-400' style={{ paddingBottom: '30px', opacity: '0.8', fontSize: '22px' }}>Co-working firms expect relief around GST</p>
                            <p class='fw-400' style={{ paddingBottom: '30px', opacity: '0.8', fontSize: '22px' }}>Incubex NestaVera plans multicity expansions</p>
                            <p class='fw-400' style={{ paddingBottom: '30px', opacity: '0.8', fontSize: '22px' }}>Incubex NestaVera To Invest Rs 100 Crore For Expansion</p>
                            <div style={{ display: 'flex' }}>
                                <span onClick={() => HandleGoNews()} class='fw-900' style={{ fontSize: '22px', color: '#000', cursor: 'pointer' }}>
                                    and much more &nbsp;
                                </span>
                                <img onClick={() => HandleGoNews()} src="assets/news/icon.png" alt="" style={{ width: '60px', cursor: 'pointer' }} />
                            </div>
                            <br />
                        </div>
                        <div class="col-xl-6 col-lg-6" data-aos="fade-right" data-aos-duration="500" style={{ textAlign: 'center' }}>
                            <a >
                                <img src="assets/news/news.png" alt="" data-aos="zoom-in-up" class="" style={{ maxWidth: '270px', minWidth: '230px' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

