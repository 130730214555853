
import React from 'react'
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { styles } from "./OurTeamStyle";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(styles);
export default function OurTeam() {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
            <div class="container mt-100 mb-100 font-montserrat">
                <h2 class='font-montserrat fs-60 fw-700' data-aos="fade-up" style={{ textAlign: 'center' }}>Our <span style={{ color: '#5EBA3F' }}>Team</span></h2>
                {/* <p style={{ lineHeight: '1.2', color: '#000', paddingLeft: '80px' }} class='fs-40 mt-10 font-montserrat'>Team of Designers</p>
                <p style={{ lineHeight: '1.2', color: '#000', paddingLeft: '80px' }} class='fs-40 font-montserrat'> and Developers</p> */}
                <Box
                    style={{ margin: 'auto', justifyContent: 'center' }}
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                >
                    <div class='row mt-50' data-aos="fade-up">
                        <div class="col-lg-6 col-md-12" style={{ margin: 'auto', textAlign: 'center' }}>

                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                className={classes.tabColor}
                            >
                                <Tab style={{ fontSize: '35px', textTransform: 'capitalize' }} label="Late. Ramji Mehrotra" {...a11yProps(0)} />
                                <Tab style={{ fontSize: '35px', textTransform: 'capitalize' }} label="Manas Mehrotra" {...a11yProps(1)} />
                                <Tab style={{ fontSize: '35px', textTransform: 'capitalize' }} label="Prachi Mehrotra" {...a11yProps(2)} />
                            </Tabs>
                        </div>
                        <div class="col-lg-6 col-md-12">

                            <TabPanel value={value} index={0}>
                                <div class='team-padding' data-aos="zoom-out" >
                                    <img src='assets/home/ramji.jpeg' alt='' style={{ height: '180px' }} />
                                    <br />
                                    <span class='font-montserrat' style={{ color: '#4EAA23', borderBottom: '1px solid black', letterSpacing: '3px' }}>FOUNDERS WORDS</span>
                                    <p class='fs-22 mt-10 mb-10 font-montserrat' style={{ color: '#000' }}>
                                        A visionary and a leading consultant on tax and foreign exchange laws in India
                                    </p>
                                    <p class='fs-25 font-montserrat' style={{ color: '#8F8F8F' }}>Late. Ramji Mehrotra</p>
                                    <p class='font-montserrat'>Chairman</p>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div data-aos="zoom-out" >
                                    <img src='assets/home/manas.jpeg' alt='' style={{ height: '180px' }} />
                                    <br />
                                    <span class='font-montserrat' style={{ color: '#4EAA23', borderBottom: '1px solid black', letterSpacing: '3px' }}>FOUNDERS WORDS</span>
                                    <p class='fs-22 mt-10 mb-10 font-montserrat' style={{ color: '#000' }}>
                                        Mr. Manas is a Chartered Accountant, Bachelors of Law & MBA from Babson College (USA).
                                    </p>
                                    <p class='fs-25 font-montserrat' style={{ color: '#8F8F8F' }}>Manas Mehrotra</p>
                                    <p class='font-montserrat'>Managing Director</p>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div data-aos="zoom-out">
                                    <img src='assets/home/prachi.jpeg' alt='' style={{ height: '180px' }} />
                                    <br />
                                    <span class='font-montserrat' style={{ color: '#4EAA23', borderBottom: '1px solid black', letterSpacing: '3px' }}>FOUNDERS WORDS</span>
                                    <p class='fs-22 mt-10 mb-10 font-montserrat' style={{ color: '#000' }}>
                                        Prachi Mehrotra has her Bachelors degree in Management Studies & MBA from The University of Nottingham, UK.
                                    </p>
                                    <p class='fs-25 font-montserrat' style={{ color: '#8F8F8F' }}>Prachi Mehrotra</p>
                                    <p class='font-montserrat'>Director</p>
                                </div>
                            </TabPanel>

                        </div>
                    </div>

                </Box>
            </div>

        </>
    )
}