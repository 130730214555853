import React from "react";
import { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Find_Taskers_Common from "components/Find_Taskers_Common";
import Avatar from "@mui/material/Avatar";
import PageviewIcon from "@mui/icons-material/Pageview";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";

export default function Contact() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [email, set_email] = useState('');
  const [message, set_message] = useState('');

  const handle_first_name = (e) => {
    set_first_name(e.target.value);
  }

  const handle_last_name = (e) => {
    set_last_name(e.target.value);
  }

  const handle_email = (e) => {
    set_email(e.target.value);
  }

  const handle_message = (e) => {
    set_message(e.target.value);
  }

  const handle_submit = () => {
    console.log(first_name, last_name, email, message);
    fetch('https://api-nestavera.ddsio.com/contacts/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        message: message
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        alert('Message Sent Successfully')
        window.location.href = "/";
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      }
      );
  }





  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/contact-us" />
        </Helmet>

        <div
          style={{ background: "#fff" }}
          class="contact-us-light pt-140 pb-100 md-pt-90 md-pb-80"
        >
          {/* <div style={{ padding: "90px 0px 80px" }} class="app-video font-montserrat "> */}
          <div class="container">
            {/* <div > */}
            <div style={{ padding: "50px 0px 50px 0px" }} class="row">
              <div data-aos="zoom-in-up" class="col-lg-6 order-lg-first mb-100">
                <div class=" text-wrapper">
                  {/* <h1 class="font-montserrat fw-700 cl-white mt-120 fs-50 md-mt-30" style={{ lineHeight: "1em", padding: "0px 0px 0px" }}>The Finest Bizness Company</h1> */}
                  <div>
                    <Avatar
                      sx={{ bgcolor: "#F5F3DA", width: 76, height: 76 }}
                      src="assets/contact/aunty.png"
                    />
                  </div>
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#000",
                      fontSize: 50,
                      fontWeight: 700,
                    }}
                    class="m-auto fw-500 fs-18 font-montserrat"
                  >
                    Let's Collaborate
                  </p>
                  <p
                    class="font-montserrat fw-500 mt-30"
                    style={{ fontSize: "20px", color: "#777777" }}
                  >
                    If you have any questions or concerns, please don't hesitate
                    to contact us. We're here to help and will do our best to
                    assist you in any way we can.
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              <div class="col-lg-6 col-md-8 ml-auto order-lg-last">
                <img
                  data-aos="zoom-in-up"
                  src="assets/contact/map.png"
                  alt="India's Best Job Search App Video"
                />
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* </div> */}

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-4 col-sm-6" data-aos="zoom-in-up">
                <div class="address-info">
                  {/* <div class="icon"><img src="images/icon/15.svg" alt="" /></div> */}
                  <LocationOnIcon style={{ color: "#000", fontSize: "60px" }} />
                  <p
                    class="font-montserrat fw-700"
                    style={{ textAlign: "center" }}
                  >
                    Location
                  </p>
                  <div class="font-montserrat fs-18 fw-600">
                    2nd & 3rd Floor, # 252, 8th Main Road, Amarajyothi Layout,
                    Domlur (Off. Embassy Golf Link Road),Bangalore 560071
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-6"
                data-aos="zoom-in-up"
                data-aos-delay="200"
              >
                <div class="address-info">
                  {/* <div class="icon"><img src="images/icon/16.svg" alt="" /></div> */}
                  <CallIcon style={{ color: "#000", fontSize: "60px" }} />
                  <p
                    class="font-montserrat fw-700"
                    style={{ textAlign: "center" }}
                  >
                    Contact
                  </p>
                  <div class="font-montserrat fs-18 fw-600">
                    <a href="mailto:business@nestavera.com">business@nestavera.com</a>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-6"
                data-aos="zoom-in-up"
                data-aos-delay="400"
              >
                <div class="address-info">
                  {/* <div class="icon"><img src="images/icon/17.svg" alt="" /></div> */}
                  <ShareIcon style={{ color: "#000", fontSize: "60px" }} />
                  <p
                    class="font-montserrat fw-700 "
                    style={{ textAlign: "center" }}
                  >
                    Social Media
                  </p>
                  <div class="font-montserrat fs-18 fw-600">
                    Follow on social media
                  </div>
                  <ul class="d-flex justify-content-center">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100054428443563&mibextid=ZbWKwL">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/315WorkAvenue?t=APTlIbbrxy5Xbc5LzxzAuA&s=09">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/nesta-vera-group/">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="form-style-light"
              data-aos="zoom-in-up"
              style={{ marginTop: "0.5rem" }}
            >
              <h1
                class="font-montserrat title fs-50 fw-600 text-center mb-20"
                style={{ color: "#000" }}
              >
                Contact Us
              </h1>
              <form id="contact-form" data-toggle="validator">
                <div class="messages"></div>
                <div class="row controls">
                  <div class="col-md-6">
                    <div class="input-group-meta form-group mb-35">
                      <label>First Name</label>
                      <input
                        class="fw-100 font-montserrat"
                        type="text"
                        placeholder="Michel"
                        name="Fname"
                        required="required"
                        data-error="Name is required."
                        id='first_name_input'
                        onChange={handle_first_name}
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group-meta form-group mb-35">
                      <label>Last Name</label>
                      <input
                        class="fw-100 font-montserrat"
                        type="text"
                        placeholder="Simon"
                        name="Lname"
                        required="required"
                        data-error="Name is required."
                        id='last_name_input'
                        onChange={handle_last_name}
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group-meta form-group mb-35">
                      <label>Your Email</label>
                      <input
                        class="fw-100 font-montserrat"
                        type="email"
                        placeholder="business@nestavera.com"
                        name="email"
                        required="required"
                        data-error="Valid email is required."
                        id='email_input'
                        onChange={handle_email}
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group-meta form-group lg mb-35">
                      <label>Your Message</label>
                      <textarea
                        class="fw-100 font-montserrat"
                        placeholder="Write your message here..."
                        name="message"
                        required="required"
                        data-error="Please,leave us a message."
                        id='message_input'
                        onChange={handle_message}
                      ></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="theme-btn-one btn-lg"
                      onClick={() => handle_submit()}
                      type="submit"
                      style={{ background: "#0E0E0E" }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
