import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useLocation,
  useHistory
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { localRoute } from "./localRoutes";
import Home from "../pages/home";
import Header from "../pages/header";
import Footer from "../pages/footer";
import Contact from "../pages/contact";
import About from "../pages/about";
import CSR from "../pages/csr";
import BlogDetails from "../pages/blog/blogDetails";
import Blogs from "../pages/blog/blogsMain";
import News from "../pages/news/newsMain";
import NewsDetails from "pages/news/newsDetails/NewsDetails";
import RealEstate from "../pages/realEstate/RealEstate";
import AngelFunding from "../pages/angelFunding";
import Coworking from "../pages/coworking";
import Education from "../pages/education";
import Trading from "../pages/trading";
import career from "../pages/career";
import NotFound from "../pages/notFound";
import NotFound500 from "../pages/notFound500";
import ScrollToTop from "components/ScrollToTop";




function RouterComponent(props) {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, [])
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path={localRoute.home} component={Home} />
          <Route exact path={localRoute.about} component={About} />
          <Route exact path={localRoute.csr} component={CSR} />
          <Route exact path={localRoute.realEstate} component={RealEstate} />
          <Route exact path={localRoute.angelFunding} component={AngelFunding} />
          <Route exact path={localRoute.coworking} component={Coworking} />
          <Route exact path={localRoute.education} component={Education} />
          <Route exact path={localRoute.trading} component={Trading} />
          <Route path={localRoute.contact} component={Contact} />
          <Route path={localRoute.career} component={career} />



          <Route path={localRoute.blogs} component={Blogs} />
          <Route path={`${localRoute.blogDetail}/:blog_id`} component={BlogDetails} />

          <Route path={localRoute.news} component={News} />
          <Route path={`${localRoute.newsDetail}/:blog_id`} component={NewsDetails} />

          <Route
            path={`${localRoute.notFound}`}
            component={NotFound}
          />
          <Route
            path={`${localRoute.notFound500}`}
            component={NotFound500}
          />
        </Switch>
        <Footer />
      </Router>
    </React.Fragment >
  );
}

export default withRouter(RouterComponent);
