import React from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import TimeChart from "./TimeChart/TimeChart";
import SubFooter from "components/subFooter";

export default function Education() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img
            data-aos="zoom-in-up"
            src="assets/service/education.jpg"
            alt=""
          />
          <div class="font-montserrat sliderBanner">
            <p
              class="font-montserrat mb-40 fw-200 fs-30"
              style={{ textAlign: "center" }}
            >
              DRIVING QUALITY EDUCATION THAT CHANGES THE WORLD, ONE STEP AT A
              TIME
            </p>
            <p
              class="font-montserrat fs-25 fw-300"
              style={{ textAlign: "center" }}
            >
              INDIA &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; SINGAPORE
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; DUBAI
            </p>
          </div>
        </div>

        <div>
          <div class="container">
            <div class="row pt-90 pb-90 sm-pt-30">
              <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
                Education
              </div>

              <div class="col-lg-3 ml-auto order-lg-first mt-30">
                <span class="fw-600" style={{ color: "#1C1C1C" }}>
                  — — Education at its best
                </span>
                <h2
                  style={{ cursor: "pointer", color: "#1C1C1C" }}
                  class="m-auto fw-800 fs-50 font-montserrat"
                >
                  Get the NestaVera Edge on Education
                </h2>
              </div>
              <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
                <div class="text-wrapper">
                  <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    As an organisation that believes firmly in the power of
                    education to change individuals, communities, countries and
                    the world, it was natural for us to venture into the world
                    of education. Today, we are proud to say that we carved a
                    niche of our own in the field of quality education with our
                    chain of international schools. Instituted with the mission
                    of providing quality education, NestaVera made its humble
                    beginning into the field of education in 2009. Over the
                    years, the Group has been involved into the management of 3
                    schools in Bengaluru. Our Institutions work towards bringing
                    an amalgamation of creativity and intellectual diversity
                    with a pragmatic approach to churn out citizens by nurturing
                    their intellectual, creative and social development. How we
                    perceive a student is how we perceive tomorrow. Students are
                    the seed of change and evolution. The organisation works
                    with such learning processes to nurture students. Each
                    student is given an opportunity to optimise his/her talent
                    and potential to its fullest.
                  </p>
                  <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    At NestaVera education management, we encourage curiosity in
                    our young students through a balanced and holistic approach
                    towards education. The curriculum we provide to Institutions
                    are delivery mechanism, coupled with unparalleled exposure
                    received through diverse learning opportunities. This
                    prepares the students for unseen challenges in a dynamic and
                    multifaceted global environment. The students receive wide
                    exposure through participation in various activities and
                    external programmes, providing fruitful opportunities for
                    learning and overall personality development. The
                    Institutions managed by NestaVera offers curricula for
                    Kindergarten to Year 12 education, including International
                    Baccalaureate Diploma Programme (IBDP), International
                    General Certificate of Secondary Education (IGCSE) and
                    Indian Certificate of Secondary Education (ICSE.)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/india.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                INDIA
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                We believe that education goes beyond classrooms and books.
                Nesta Vera has planted its roots in India to enrich the quality
                of education.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 order-lg-last order-md-last row-padd0">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/singapore.png"
                alt=""
                style={{ filter: "brightness(0.3)", padding: "0px" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                SINGAPORE
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Empowering the future generation in Singapore to innovate and
                build a bright future by creating an engaging learning
                environment.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/dubai.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                DUBAI
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Molding the best minds in Dubai by providing quality education
                and partnering with parents to provide their children with a
                lasting success in school, work, and life.
              </p>
            </div>
          </div>
        </div>

        <TimeChart />

        <SubFooter />
      </div>
    </>
  );
}
