import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validation';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';
import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
// import { createImageUrl } from "../context/actions/subscriberAction/createImageUrl";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { Grid, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  file_url: '',
  subscriber_message: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const file_url = ""

const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function Career_Form(props) {
  console.log("propss", props);
  console.log("props.jobsDetail", props.jobsDetail)
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
  const [imageUrl, setImageUrl] = React.useState('');
  const [number, setNumber] = useState([])
  const [state, setState] = useState(init);
  const [uploadResponse, setUploadResponse] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);

  const handleClose = () => {
    setState(init);
    setImageUrl("")
    setOpen(false)
    setErrors(!validation(state));
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };



  const handleChangeNumberLeadInput = (event) => {
    console.log('event', event.target.value)
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }


  const handleClick = async (e) => {
    e.preventDefault()
    // setErrors(validation(state));

    if (
      state.subscriber_name !== '' &&
      state.subscriber_email !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.file_url !== ''
      // state.file_url !== ''
    ) {
      // const postData = {
      //   name: state.subscriber_name,
      //   email: state.subscriber_email,
      //   phone: state.subscriber_phone,
      //   // resume: state.file_url,
      //   job: '1',
      //   resume: imageUrl,
      // };

      const postData = new FormData();
      postData.append('job', '1');
      postData.append('name', state.subscriber_name);
      postData.append('email', state.subscriber_email);
      postData.append('phone', state.subscriber_phone);
      // postData.append('resume', imageUrl); // Assuming 'resume' is the field name on the server for the uploaded file
      postData.append('resume', state.file_url);


      await createSubscriber(postData)(subscriberDispatch)
      console.log('Create Subscriber Detail', postData)

      setState(init);
      setImageUrl("")
      setUploadResponse(false)
      setOpenNotification(true);
    }
  }



  // const handleCapture = ({ target }) => {
  //   console.log('upload Files')
  //   const { name, value } = target
  //   if (value != '') {
  //     const fileReader = new FileReader();
  //     const name1 = target.accept.includes('pdf');

  //     fileReader.readAsDataURL(target.files[0]);

  //     let formData = new FormData();
  //     formData.append('file', target.files[0]);
  //     console.log('file', target)
  //     setImageUrl(target.files[0].name)
  //     setUploadResponse(false)
  //     // createImageUrl(formData)(subscriberDispatch)
  //   }
  // };

  console.log('state', state)
  const handleCapture = (e) => {
    const file = e.target.files[0];
    setState({ ...state, file_url: file });
  };


  return (
    <div >
      <a
        size="large" style={{ border: 'none', background: '#5EBA3F', color: '#fff', cursor: "pointer" }}
        onClick={handleOpen}
        // style={{ borderRadius: "10px", color: "black"}}
        class="mt-10 theme-btn-four">
        Apply Now
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <div style={{ height: 610, overflowY: 'auto', display: 'inline-grid', padding: "0px", position: "absolute" }} class="form-left col-lg-4 col-md-8 wow fadeInUp">
            <Snackbar open={openNotification} onClose={handleCloseNotification} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert severity="success" sx={{ width: '100%' }}>
                Form Successfully Submitted!
              </Alert>
            </Snackbar>
            <div style={{ margin: "0px" }} class="consultation-form-area pl-xl-3 content-mt-md-50">
              <div class="consultation-form">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h4 class="pb-10 font-montserrat fs-25 fw-700">Apply for a position!</h4>
                  <CloseIcon style={{ cursor: "pointer", floatDirection: "left" }} onClick={handleClose} />
                </div>
                <form action="#" encType="multipart/form-data">
                  <div class="font-montserrat input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Full Name*"
                        size="small"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                    </Grid>
                    <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Email*"
                        size="small"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                    </Grid>                  </div>
                  {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Phone*"
                        size="small"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                      {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                    </Grid>
                  </div>
                  <br />
                  <div >
                    <Grid xs={12} >
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={handleCapture}
                      />
                      {/* <DropzoneAreaBase
                        acceptedFiles={['application/pdf']} // Specify the accepted file types (PDF in this case)
                        dropzoneText="Drag and drop a PDF file here or click"
                        onChange={handleCapture}
                        filesLimit={1}
                        showAlerts={false}
                      /> */}
                    </Grid>
                  </div>

                  <button
                    style={{ height: "50px", fontWeight: "600", background: '#000' }}
                    onClick={handleClick}
                    type="submit"
                    class="mt-20 template-btn">
                    Submit <i class="flaticon-right-arrow"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

