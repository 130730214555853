import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./RealEstateStyle";
import RealEstate from '../../components/realEstate'
const RealEstatePage = React.memo((props) => {
  return (
    <>
      <RealEstate />
    </>
  );
});

export default withRouter(RealEstatePage);
