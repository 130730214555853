import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CoworkingStyle";
import Coworking from '../../components/coworking'
const CoworkingPage = React.memo((props) => {
  return (
    <>
      <Coworking />
    </>
  );
});

export default withRouter(CoworkingPage);
