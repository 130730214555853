let timelineElements = [
  {
    id: 1,
    title: "S R Management Private Limited",
    description: "S R Management Private Limited is Trade Service Provider of arbitration services, business management, public relations, arbitration",
    date: "Dec 2000",
    icon: "work"
  },
  {
    id: 2,
    title: "LINTAS EQUITY ADVISORS PRIVATE LIMITED",
    description: "Operations in connection with project management, planning, organisation, management information etc.",
    date: "June 2008",
    icon: "work"
  },
  {
    id: 3,
    title: "NestaVera Investment Advisory Solutions LLP",
    description:
      "Specializing in quantitative strategies, the firm was established withthe aim of delivering returns to investors through long-term compound growth",
    date: "Jan 2012",
    icon: "work"
  },
  {
    id: 4,
    title: "Blooming Capital Management Private Limited",
    description: " BLOOMING CAPITAL MANAGEMENT PRIVATE LIMITED is a Financial, insurance, and real estate industries based company",
    date: "April 2012",
    icon: "work"
  },
  {
    id: 5,
    title: "Neoteric Globe Services Private Limited",
    description: "Neoteric Globe Services Private Limited is majorly in Trading business from last 7 years",
    date: "March 2015",
    icon: "work"
  },
  {
    id: 6,
    title: "NestaVera Ventures Private Limited",
    description: "Specializing in quantitative strategies, the firm was established withthe aim of delivering returns to investors through long-term compound growth",
    date: "May 2016",
    icon: "work"
  },
  {
    id: 7,
    title: "Nine Sea Global DMCC",
    description: "World's flagship Free Zone and Government of Dubai Authority on commodities trade and enterprise",
    date: "May 2016",
    icon: "work"
  },
  {
    id: 8,
    title: "NEXUS BANQUET SERVICES LLP",
    description: "Nexus Banquet Services provides banquet hall with creative, elegant and exotic feel.",
    date: "Sept 2017",
    icon: "work"
  },
];

export default timelineElements;
