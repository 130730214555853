import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CSRStyle";
import CSR from '../../components/csr'
const CSRPage = React.memo((props) => {
  return (
    <>
      <CSR />
    </>
  );
});

export default withRouter(CSRPage);
