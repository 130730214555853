import React from "react";
import "./BlogCard.css";
import moment from 'moment';

export default function BlogCard({ blog, openBlog }) {
  return (
    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
      <div class="post-meta">
        <img src={`${process.env.REACT_APP_API_HOST}${blog.cover}`} alt={blog.title} class="image-meta" onClick={openBlog} />
        <div class="fs-14 font-montserrat tag">{blog.category_display} | {moment(blog?.updated_at).format('MMMM Do YYYY')}</div>
        <h3>
          <a
            style={{ cursor: "pointer" }} class="font-montserrat title fw-600"
            onClick={openBlog}
          >
            {blog.title}
          </a>
        </h3>
        {
            blog.external_link && (
              <a
                style={{ cursor: "pointer", color: '#4C4C4C' }}
                class="read-more d-flex justify-content-between align-items-center"
                href={blog.external_link}
                target="_blank"
              >
                <span>Read More</span>
                <i class="flaticon-right-arrow"></i>
              </a>
            ) || (
              <a
                style={{ cursor: "pointer", color: '#4C4C4C' }}
                class="read-more d-flex justify-content-between align-items-center"
                onClick={openBlog}
              >
                <span>Read More</span>
                <i class="flaticon-right-arrow"></i>
              </a>
            ) 
        }
      </div>
    </div>
  );
}
