import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
// import "./BlogsStyle";
import Blogs from '../../../components/blog/blogs'

const NewsPage = React.memo((props) => {
  return (
    <>
      <Blogs />
    </>
  );
});

export default withRouter(NewsPage);
