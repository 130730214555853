import * as React from "react";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { localRoute } from "routes/localRoutes";

export default function ChatBob() {
  const history = useHistory();

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };
  return (
    <>
      <div className="chatBob">
        <ChatBubbleIcon
          style={{
            fontSize: "60px",
            color: "#000",
            marginTop: "10px",
          }}
        />
        <h2 class="font-montserrat fs-20 fw-800">
          Do you have more questions?
        </h2>
        <p class="pt-10 font-montserrat fs-16 fw-600">
          End-to-end payments and financial management in a single solution.
          Meet the right platform to help realize.
        </p>
        <div class="pt-20 pb-10">
          <Button
            size="large"
            style={{
              background: "#5EBA3F",
              color: "#fff",
              fontWeight: "600",
              fontSize: "16px",
              padding: "10px 20px",
            }}
            onClick={handleBackContact}
          >
            Shoot a Direct Mail
          </Button>
        </div>
      </div>
    </>
  );
}
