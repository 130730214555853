import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ4() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              Timeline to fund?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "The timeline to fund an investment can vary greatly depending on
              the specifics of the investment and the process involved. Here is
              a general overview of the steps that may be involved in funding an
              investment: Research: This step involves researching potential
              investments to determine which ones are worth pursuing. This may
              involve reviewing financial statements, analyzing market trends,
              and consulting with financial advisors. Due diligence: Once an
              investment has been identified, it is important to conduct
              thorough due diligence to understand the risk and potential return
              of the investment. This may involve reviewing legal documents,
              conducting interviews with management, and reviewing financial
              projections. Negotiation: If the investment looks promising, the
              next step is to negotiate the terms of the investment. This may
              include determining the amount of the investment, the ownership
              stake, and the terms of the agreement. Closing: Once the terms
              have been agreed upon, the final step is to close the deal and
              fund the investment. This may involve transferring the investment
              funds to the company or setting up a payment plan. Overall, the
              timeline to fund an investment can take anywhere from a few weeks
              to several months, depending on the complexity of the investment
              and the due diligence process. It is important to be patient and
              thorough in this process to ensure that the investment is a good
              fit for your financial goals."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What do your most successful investments have in common?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              Our most successful investments have all shared a few key
              characteristics in common. Firstly, they have all been in
              companies with strong management teams and clear visions for the
              future. These leaders have been able to effectively execute their
              plans and drive the company forward. Additionally, these
              investments have all been in industries with strong growth
              potential and favorable market conditions. Finally, we have done
              thorough due diligence and research on each of these investments
              to ensure they align with our investment goals and risk tolerance.
              Overall, these investments have had a strong foundation and
              demonstrated the ability to generate consistent, long-term returns
              for our clients.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12bh-content"
            id="panel12bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What do investors look for in a company?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "Investors look for a number of key factors when evaluating a
              company for potential investment. Some of the key criteria that
              investors consider include: Financial stability: Investors want to
              see that a company has a strong financial foundation and is able
              to generate consistent profits. This includes looking at financial
              ratios such as the company's debt-to-equity ratio, return on
              investment, and earnings per share. Market potential: Investors
              want to see that a company is operating in a growing and expanding
              market with strong demand for its products or services. This
              includes evaluating the size of the target market, competitive
              landscape, and potential for future growth. Management team:
              Investors want to see that a company is led by a competent and
              experienced management team that is able to execute on its
              strategic vision and goals. Product or service offering: Investors
              want to see that a company has a unique or differentiated product
              or service offering that is in high demand in the market.
              Competitive advantage: Investors want to see that a company has a
              strong competitive advantage, whether it be through its brand,
              intellectual property, or access to key resources or partnerships.
              Exit strategy: Investors want to see that a company has a clear
              plan for how it will generate a return on investment, whether it
              be through a sale, IPO, or other exit strategy."
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </div>
  );
}
