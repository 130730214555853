import React from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import TimeChart from "./TimeChart/TimeChart";
import SubFooter from "components/subFooter";
import Gallery from "components/about/Gallery";

export default function AngelFunding() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img data-aos="zoom-in-up" src="assets/service/angel.jpg" alt="" />
          <div class="font-montserrat sliderBanner">
            <p
              class="font-montserrat mb-40 fw-200 fs-30"
              style={{ textAlign: "center" }}
            >
              NURTURING TODAY'S STARTUPS T0 LEAD TOMORROW'S ECONOMY
            </p>
            <p
              class="font-montserrat fs-25 fw-300"
              style={{ textAlign: "center" }}
            >
              INDIA &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; SINGAPORE
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; DUBAI
            </p>
          </div>
        </div>
        <div>
          <div class="container">
            <div class="row pt-90 pb-90 sm-pt-30">
              <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
                Angel-Funding
              </div>

              <div class="col-lg-3 ml-auto order-lg-first mt-30">
                <span class="fw-600" style={{ color: "#1C1C1C" }}>
                  — — Angel funding at its best
                </span>
                <h2
                  style={{ cursor: "pointer", color: "#1C1C1C" }}
                  class="m-auto fw-800 fs-50 font-montserrat"
                >
                  Get the NestaVera Edge on Angel funding
                </h2>
              </div>
              <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
                <div class="text-wrapper">
                  <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    Like any growing organisms, the startups also need room for
                    growth, constant nurturing and ideal conditions to flourish.
                    In today's India, there is an environment that is conducive
                    for startups to initiate operations. At NestaVera, we just
                    don't invest in startups; we facilitate an environment for
                    startups to reach for the skies.With veteran investors who
                    have years of experience in all facets of business,
                    NestaVera is all set to give the startup ecosystem the
                    thrust it needs. To explain it in simple words - Angel
                    Funding involves contributing finance into the growth of
                    small business at an earlier stage, and also potentially
                    contributing with advice and business experience.It involves
                    making our own decision about investmentsin return for
                    equity in the business. The amount of Investment is
                    flexible, it could be small amount to get the startup off
                    the ground or a larger amount.The Aim is to provide only
                    fund, insight and business advice but it isn't the job of a
                    angel investor to build the company. Angel Investment
                    involves investing your own funds although the actual entity
                    that provides funding may be a trust, business, limited
                    liability company, investment fund or other vehicle unlike
                    venture capitalists who manage and investpooled money of
                    others through professionally managed funds. Angel capital
                    is the answer to the gap that occurs for startups in-between
                    seed funding (which usually comes from friends and family)
                    and more robust startup financing through formal venture
                    capital.There are reports providing evidence that startups
                    that have received angel funding are more likely to succeed
                    and have a high rate of growth and survival than companies
                    that rely on other forms of initial financing. NestaVera has
                    provided Angel funds for - Zolostays is a young, funded
                    startup that provides unique, premium rentals. They create
                    communities in our fully managed co-living spaces enabled by
                    technology, driven by intelligence and supported by
                    professionalism.The company partners with real estate
                    builders to offer working professionals affordable, fully
                    managed accommodations, which includes food, utilities and
                    housekeeping. AASPL, which is into Design & Development,
                    Production, Testing, Life Cycle Support and MRO services for
                    Accessories and Systems for Aerospace and Defence
                    applications. The major focus is on indigenous development
                    of high value aero components / accessories. eTECH, is a
                    company which focuses on product design and engineering
                    services. We enable startups to absorb our entrepreneurial
                    knowledge and investment experience. So, that they enter the
                    business world with a bang, sail swiftly through the
                    uncertain dynamics of the market, venture into the untapped
                    segments and write a success story.
                  </p>
                  <p class="fw-400 fs-16 mt-10" style={{ color: "#1C1C1C" }}>
                    FINANCE
                    <br />
                    Significant investments in the Finance IndustryRead More...
                    We enable startups to absorb our entrepreneurial knowledge
                    and investment experience. So, that they enter the business
                    world with a bang, sail swiftly through the uncertain
                    dynamics of the market, venture into the untapped segments
                    and write a success story.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div
              class="col-lg-6 col-md-8 ml-auto order-lg-first"
              style={{ position: "relative", padding: "0px" }}
            >
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/india.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                INDIA
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 order-lg-last"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Experience the luxury of well thought residential and corporate
                spaces and get a unique experience of Indian properties backed
                by NestaVera.
              </p>
            </div>
          </div>
        </div>

        <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div
              class="col-lg-6 col-md-8 ml-auto order-lg-last"
              style={{ position: "relative", padding: "0px" }}
            >
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/singapore.png"
                alt=""
                style={{ filter: "brightness(0.3)", padding: "0px" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                SINGAPORE
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 order-lg-first"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Make Singapore your preferred destination and enjoy a unique
                experience in the choicest of NestaVera-approved properties.
              </p>
            </div>
          </div>
        </div>

        <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div
              class="col-lg-6 col-md-8 ml-auto order-lg-first"
              style={{ position: "relative", padding: "0px" }}
            >
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/dubai.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                DUBAI
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 order-lg-last"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Indulge in the state of art infrastructure of Dubai's real
                estate and carve your desired life with the best of properties
                backed by NestaVera.
              </p>
            </div>
          </div>
        </div> */}

        <Gallery />

        <TimeChart />

        <SubFooter />
      </div>
    </>
  );
}
