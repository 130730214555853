import React from 'react'

export default function OurAwards() {

    return (
        <>
            <div class="pt-20 mb-180 " >
                <div class="container">
                    <div class="section-heading mb-100">
                        <h2 class="font-montserrat fs-60 fw-700 text-center">Our <span style={{ color: '#FCC75F' }}>Awards</span></h2>
                    </div>
                    <div class="row justify-content-around">
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="400">
                            <div class="img-meta d-flex align-items-center justify-content-center"><img src="assets/home/awards/1.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="800">
                            <div class="img-meta d-flex mt-100 align-items-center justify-content-center"><img src="assets/home/awards/2.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="1200">
                            <div class="img-meta d-flex align-items-center justify-content-center"><img src="assets/home/awards/3.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="1600">
                            <div class="img-meta d-flex  mt-100 align-items-center justify-content-center"><img src="assets/home/awards/4.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="2000">
                            <div class="img-meta d-flex align-items-center justify-content-center"><img src="assets/home/awards/5.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                        <div class="item" data-aos="fade-up" style={{ padding: '10px' }} data-aos-duration="2400">
                            <div class="img-meta d-flex  mt-100 align-items-center justify-content-center"><img src="assets/home/awards/6.png" alt="" style={{ width: '160px' }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="logo-wrapper d-flex flex-wrap justify-content-center align-items-center mt-100" >
                <img src="../../../../assets/logoweb/uber.png" alt="" style={{ height: '80px', padding: '10px' }} />
                <img src="../../../../assets/logoweb/blue-smart.svg" alt="" style={{ height: '80px', padding: '10px' }} />
                <img src="../../../assets/logoweb/rapido.png" alt="" style={{ height: '80px', padding: '10px' }} />
                <img src="../../../../assets/logoweb/jihovah.png" alt="" style={{ height: '80px', padding: '10px' }} />
                <img src="../../../../assets/logoweb/uncled.png" alt="" style={{ height: '80px', padding: '10px' }} />
                <img src="../../../../assets/logoweb/flogo.png" alt="" style={{ height: '80px', padding: '10px' }} />
            </div> */}


        </>
    );
}
