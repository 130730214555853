import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ1() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What's the company history as an investor?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "Our company has a long and successful history as an investor. We
              were founded over 30 years ago with a focus on providing financial
              support to businesses in need of capital to grow and thrive. Over
              the years, we have built a reputation for being a reliable and
              trustworthy partner for companies looking to expand and reach
              their full potential. We have always had a keen eye for
              identifying opportunities for investment, and our team of
              experienced professionals is skilled at evaluating the potential
              risks and rewards of each potential investment. We have invested
              in a wide range of industries, including technology, healthcare,
              education, coworking and real estate, and we have a track record
              of delivering strong returns for our investors."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What do you look for in a potential investment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "When considering a potential investment, we look for companies
              that have a strong track record of financial stability and growth.
              This includes a history of consistent revenue and profit
              generation, as well as a solid balance sheet with minimal debt. We
              also look for companies with a clear and compelling business
              model, as well as a competitive advantage in their industry. This
              could be through a unique product or service offering, or a strong
              brand presence. We also consider the management team of the
              company, as they will play a crucial role in the success of the
              investment. We look for experienced and capable leaders with a
              proven track record of success in their respective fields.
              Finally, we consider the potential return on investment and
              evaluate the risk associated with the company. We strive to find a
              balance between maximizing returns while minimizing risk to ensure
              the long-term success of our investment."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What will be the role of NestaVera?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "NestaVera is a leading investment company that focuses on
              providing financial support to startups and small businesses. Our
              goal is to help these companies grow and succeed by providing them
              with the necessary resources and expertise to reach their full
              potential. As an investor, NestaVera plays a crucial role in the
              development of these businesses. We provide financial support
              through equity investments and also offer strategic guidance and
              support to help these companies navigate the challenges of the
              modern business world. In addition to our financial support, we
              also offer a range of other services to our portfolio companies,
              including access to our network of industry experts and advisors,
              marketing and branding support, and business development
              assistance. Our team is dedicated to helping our portfolio
              companies succeed and we are committed to playing an active role
              in their growth and development. Whether through providing
              financial support, offering strategic guidance, or assisting with
              business development, NestaVera is committed to helping our
              portfolio companies succeed and thrive in today's competitive
              marketplace."
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </div>
  );
}
