import React, { useEffect, useState, useContext } from "react";
import "aos/dist/aos.css";
import { format } from "date-fns";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { localRoute } from '../../../routes/localRoutes';
import BlogCard from "../blogCard/BlogCard";
import moment from 'moment';
import axios from "../../../axios";
import FeaturedBlogCard from "../featuredBlogCard";
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function Blogs(props) {
  const [blogItemsList, setBlogItemsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [mainBlog, setMainBlog] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);
  const [totalBlogPages, setTotalBlogPages] = useState(0);
  const [totalBlogsCount, setTotalBlogsCount] = useState(0);
  const [nextBlogPage, setNextBlogsPage] = useState(0);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const history = useHistory();

  const currentRoute = useHistory().location.pathname.toLowerCase();

  const currentID = useHistory().location.hash.toLowerCase();

  const OpenBlog = (blog_id) => {
    setSelectedBlogId(blog_id)
    history.push(`${localRoute.blogDetail}/${blog_id}`)
  };

  const OpenCareer = () => {
    history.push(`${localRoute.career}`)
  };

  const category = (currentRoute === '/news' ? 'news' : 'blog')
  const categoryDisplay = (currentRoute === '/news' ? 'News' : 'Blogs')

  useEffect(() => {
    axios
      .get(`/articles/category/${category}/`)
      .then((res) => {
        const pageCount = Math.ceil(res.data.count / 10);
        setTotalBlogPages(pageCount);
        setBlogItemsList(res.data.results);
        setNextBlogsPage(res.data.next);
        setTotalBlogsCount(res.data.count)
        setMainBlog(res.data.results[0])
      })
      .catch((e) => {
        console.error(e);
      });

    axios
      .get("/tags/")
      .then((res) => {
        setTagsList(res.data);
      })
      .catch((e) => {
        console.error(e);
      });

    axios
      .get(`/categories/?parent_category=${category}`)
      .then((res) => {
        setCategoriesList(res.data.results);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const updateCategorySelection = (selectedCategory) => {
    setSelectedCategory(selectedCategory)
    axios
      .get(`/articles/category/${category}/?category=${selectedCategory}`)
      .then((res) => {
        const pageCount = Math.ceil(res.data.count / 10);
        setTotalBlogPages(pageCount);
        setBlogItemsList(res.data.results);
        setNextBlogsPage(res.data.next);
        setTotalBlogsCount(res.data.count)
        setMainBlog(res.data.results[0])
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const updateTagSelection = (selectedTag) => {
    axios
      .get(`/articles/category/${category}/?tags=${selectedTag}`)
      .then((res) => {
        const pageCount = Math.ceil(res.data.count / 10);
        setTotalBlogPages(pageCount);
        setBlogItemsList(res.data.results);
        setNextBlogsPage(res.data.next);
        setTotalBlogsCount(res.data.count)
        setMainBlog(res.data.results[0])
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://nestavera.com/blogs" />
      </Helmet>

      <div class="blog-page-white-bg blog-v3">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="feature-blog-three font-montserrat">
                {/* {filterBlogDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                    return ( */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => OpenBlog(mainBlog?.id)}
                  class="post-meta"
                >
                  <img src={`${process.env.REACT_APP_API_HOST}${mainBlog?.cover}`} />
                  <div class="post">
                    <p class='font-montserrat' style={{ color: "#4C4C4C" }}>
                      {moment(mainBlog?.updated_at).format('MMMM Do YYYY')}
                    </p>
                    <h1 class='font-montserrat fw-700'>
                      {mainBlog?.title}
                    </h1>
                    <br />
                    <p class='font-montserrat' style={{ color: "#6D6E76" }}>
                      {mainBlog?.short_body}
                    </p>
                    {
                      mainBlog.external_link ?
                        (
                          <Button
                            variant="contained"
                            style={{ background: "#5EBA3F", marginTop: 20 }}
                            endIcon={<ArrowForwardIosIcon />}
                            href={mainBlog.external_link}
                            target="_blank"
                          >
                            Read More
                          </Button>
                        )
                        :
                        (
                          <Button
                            variant="contained"
                            style={{ background: "#5EBA3F", marginTop: 20 }}
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() => OpenBlog(mainBlog?.id)}
                          >
                            Read More
                          </Button>
                        )
                    }
                  </div>
                </div>
                {/* );
                                })} */}
              </div>

              {/* <div class="page-pagination-one pt-15">
                                <ul class="d-flex align-items-center">
                                    <Pagination count={totalPage} page={paginationCount} onChange={handleChangePage} onClick={window.scrollTo(0, 0)} color="primary" variant="outlined" onRowsPerPageChange={handleChangeRowsPerPage} />
                                </ul>
                            </div> */}
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-sidebar-one">
                <div class=" mb-60">
                  <h4 class="sidebar-title font-montserrat fw-700">
                    Categories
                  </h4>
                  {/* {blogCategoriesDetail.map(item => */}
                  <ul>
                    {categoriesList &&
                      categoriesList.map((category) => (
                        <div
                          class='categories-hover mb-15'
                          style={{
                            border: "1.5px solid #F9E1DD",
                            display: 'flex',
                          }}
                          onClick={() => updateCategorySelection(category.id)}
                        >
                          {category.file ?
                          (
                            <img
                              src={`${category.file}`}
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            />
                          ): (
                              <AssessmentIcon style={{ fontSize: '45px' }} />
                            )
                            }
                          <a
                            class="font-montserrat"
                            style={{
                              cursor: "pointer",
                              fontSize: 26,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              padding: "0px 0px 0px 10px"
                            }}
                          >
                            {category.title}
                          </a>
                        </div>
                      ))}
                  </ul>
                  {/* )} */}
                </div>

                {/* <div class="sidebar-keyword">
                  <h4 class="sidebar-title font-montserrat fw-700">All Tags</h4>
                  <ul class="cleafix">
                    {tagsList &&
                      tagsList.map((tag) => (
                        <li class='font-montserrat'>
                          <a 
                            class='fw-700'
                            onClick={
                              () => updateTagSelection(tag.id)
                            }>
                              {tag.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div> */}
              </div>
            </div>

          </div>

          <div class="font-montserrat feature-blog-one blog-page-bg">
            <div class="section-heading mt-50 mb-50 pb-40">
              <h2 class="font-montserrat fs-40 fw-700">What to read next</h2>
            </div>

            <div class="container">
              <div class="row">
                {blogItemsList &&
                  blogItemsList.map((blog) => (
                    <BlogCard key={blog.id} blog={blog} openBlog={() => OpenBlog(blog.id)} />
                  ))}
              </div>
            </div>
          </div>

          <div class="font-montserrat feature-blog-one blog-page-bg">
            <div class="section-heading mb-50">
              <h2 class="font-montserrat fs-40 fw-700">Featured {categoryDisplay}</h2>
            </div>

            <div class="container">
              <div class="row">
                {blogItemsList &&
                  blogItemsList.map((blog) => (
                    <FeaturedBlogCard key={blog.id} blog={blog} openBlog={() => OpenBlog(blog.id)} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" data-aos="zoom-out">
        {/* <div class='col-lg-6 m-auto text-center'>
          <h4 class="fs-50 font-montserrat fw-700">
            Join our team to be a part of our story
          </h4>
          <p class='font-montserrat fs-22 mt-20 fw-400' style={{ color: '#6D6E76' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
          </p>
          <Button size="large" style={{ background: '#5EBA3F', color: '#fff', fontWeight: '700', margin: '20px 10px 70px 10px', padding: '10px 25px 10px 25px' }} onClick={OpenCareer}>
            Join Now
          </Button>
        </div> */}
      </div>
    </div>
  );
}
