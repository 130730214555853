import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ2() {
  const [expanded, setExpanded] = React.useState("panel5");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <>
       
        <Accordion
          class="mt-10"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What are the industries nestavera invests in?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "NestaVera is a diverse investment company that holds a portfolio
              in a variety of sectors. Our main focus is on real estate, where
              we invest in both commercial and residential properties. We also
              have a strong presence in angel funding, where we provide seed
              funding to early-stage startups and entrepreneurs. Additionally,
              we are invested in technology companies, including software
              development and hardware manufacturing. In addition to these
              traditional investments, we also have a growing portfolio in
              coworking spaces and education. Our coworking spaces provide
              flexible and collaborative workspaces for entrepreneurs and small
              businesses. We also invest in educational institutions, both
              online and in-person, to provide quality learning opportunities
              for people of all ages. Overall, NestaVera is dedicated to finding
              and supporting innovative and promising ventures in a variety of
              sectors. We believe that by diversifying our portfolio, we can
              mitigate risk and maximize our potential for growth."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              When is the best time to seek venture funding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              The best time to seek venture funding is when a company has a
              clear and defined business plan, a proven track record of success,
              and a solid financial foundation. This typically occurs after a
              company has successfully launched and is generating revenue.
              Seeking venture funding at this stage allows a company to scale
              and accelerate its growth. It is also important to have a clear
              understanding of the investment needs and goals, as well as a
              strong pitch to present to potential investors.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What is the minimum and maximum NestaVera would be prepared to invest?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "NestaVera is a venture capital firm that is dedicated to
              investing in and supporting early-stage companies with high-growth
              potential. We are committed to providing financial and operational
              support to help these companies achieve success and reach their
              full potential. Depending on the specific needs and goals of the
              business. We carefully evaluate each investment opportunity and
              tailor our investment to meet the unique needs of each company. At
              NestaVera, we believe that a strong partnership with the companies
              we invest in is key to their success. We are dedicated to working
              closely with our portfolio companies and providing the resources
              and support they need to grow and thrive. So, we are always
              willing to invest in the right companies, no matter the size of
              the investment."
            </Typography>
          </AccordionDetails>
        </Accordion>
        
      </>
    </div>
  );
}
