import React from 'react'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
export default function OurCoreValues() {


    return (
        <>
            <div class="fancy-feature-sixteen mt-70 md-mt-70" id="feature">
                <div class="container" data-aos="fade-up" data-aos-easing="linear" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                    <h2 class="fs-40 fw-900 pt-30 pb-50 font-montserrat" style={{ color: '#414141' }}>Our Core Values </h2>

                    <div class="row align-items-center mb-80">
                        <div class="col-lg-6" >
                            <div class="font-montserrat text-wrapper">
                                <div style={{ display: "flex" }}>

                                    <PhoneIphoneIcon style={{ fontSize: '35px', marginTop: '20px', marginRight: '10px' }} />
                                    <div style={{ borderTop: '3px solid #000', paddingTop: '20px' }}>
                                        <h2 class="font-montserrat fw-800 fs-25" style={{ color: '#0E0E0E' }}>
                                            Strategic Partnerships
                                        </h2>
                                        <p class='fs-18 mt-20' style={{ color: '#5E5B5B', fontWeight: '300' }}>
                                            With an aim of economic growth, our approach includes the establishment of business relations between enterprises by strategic partnerships.
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div style={{ display: "flex" }}>

                                    <PhoneIphoneIcon style={{ fontSize: '35px', marginTop: '20px', marginRight: '10px' }} />
                                    <div style={{ borderTop: '3px solid #000', paddingTop: '20px' }}>
                                        <h2 class="font-montserrat fw-800 fs-25" style={{ color: '#0E0E0E' }}>
                                            Management
                                        </h2>
                                        <p class='fs-18 mt-20' style={{ color: '#5E5B5B', fontWeight: '300' }}>
                                            Education, Real Estate, Co-Working spaces, Angel Funding, Trading and Education
                                            We strongly believe in the future of these sectors. We have our ventures in all these spaces and deal in their management.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="font-montserrat text-wrapper">

                                <div style={{ display: "flex" }}>

                                    <LaptopChromebookIcon style={{ fontSize: '35px', marginTop: '20px', marginRight: '10px' }} />
                                    <div style={{ borderTop: '3px solid #000', paddingTop: '20px' }}>
                                        <h2 class="font-montserrat fw-800 fs-25" style={{ color: '#0E0E0E' }}>
                                            Quantitative Strategies
                                        </h2>
                                        <p class='fs-18 mt-20' style={{ color: '#5E5B5B', fontWeight: '300' }}>
                                            We specialise in Quantitative strategies and support start-ups' with stocks which have the potential to outperform and deliver long-term growth.
                                        </p>
                                    </div>
                                </div>

                                <br />
                                <br />
                                <div style={{ display: "flex" }}>

                                    <LaptopChromebookIcon style={{ fontSize: '35px', marginTop: '20px', marginRight: '10px' }} />
                                    <div style={{ borderTop: '3px solid #000', paddingTop: '20px' }}>
                                        <h2 class="font-montserrat fw-800 fs-25" style={{ color: '#0E0E0E' }}>
                                            Business Growth
                                        </h2>
                                        <p class='fs-18 mt-20' style={{ color: '#5E5B5B', fontWeight: '300' }}>
                                            We support a well-rounded growth of a start-up. With investments, we also provide start-ups with advice and business insights from our community of business veterans with over 60-years of combined experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
