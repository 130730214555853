import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'

const menuList = [
  { label: "Home", link: "/home.html" },
  { label: "About", link: "/about-us" },
  { label: "CSR", link: "/csr" },
  { label: "Blog", link: "/blogs" },
  { label: "News", link: "/news" },
  { label: "Contact Us", link: "/contact-us" },
];

export default function Header(props) {
  var body = undefined;
  var menu = undefined;
  const location = useLocation();

  useEffect(() => {
    body = document.querySelector("body");
    menu = document.querySelector(".menu-icon");

    var toggleClass = function toggleClass(element, stringClass) {
      if (element.classList.contains(stringClass))
        element.classList.remove(stringClass);
      else element.classList.add(stringClass);
    };

    menu.addEventListener("click", function () {
      return toggleClass(body, "nav-active");
    });
  }, [])

  return (
    <>
      <header class="cd-header">
        <div class="header-wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a href="/home.html" class="hover-target animsition-link mainLogo">
            <img src="../../assets/home/NV_LOGO.png" alt="" />
          </a>
          <div class="" style={{ display: 'flex' }}>
            <div class="menu-icon hover-target" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <span class="menu-icon__line menu-icon__line-left"></span>
              <span class="menu-icon__line"></span>
              <span class="menu-icon__line menu-icon__line-right"></span>
            </div>
          </div>
        </div>
      </header>
      <div class="navhome">
        <div class="nav__content" style={{ padding: '20px', paddingTop: "40px", paddingBottom: "40px" }}>
          {/* <div class="curent-page-name-shadow">{menuList.filter((value) => (value.link === location.pathname))[0].label}</div> */}
          <ul class="nav__list">
            {menuList.map((menu, index) => (
              <>
                {menu.link === location.pathname ?
                  <li class="nav__list-item active-nav"><a data-toggle="collapse" href={menu.link} class="hover-target"
                    role="button" aria-expanded="false" aria-controls="collapseSub">{menu.label}</a></li> :
                  <li class="nav__list-item"><a href={menu.link} class="hover-target animsition-link">{menu.label}</a></li>}
              </>
            ))
            }
            
          </ul>
        </div>
      </div>
    </>
  );
}
