import React from "react";
import "aos/dist/aos.css";

export default function Gallery() {
  return (
    <>
      <div class="fancy-feature-thirtyTwo pb-60 md-pt-50 md-pb-60 rGallery">
        <div class="container">
          <div class="title-style-eleven">
            <h1
              class="font-montserrat fs-50 fw-700 text-center pb-40"
              style={{ color: "#000" }}
            >
              Our <span style={{ color: "#FD6A5E" }}>Gallery</span>
            </h1>
          </div>
          <div class="row align-items-center justify-content-center">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div class="title-style-eleven">
                <h2
                  class="font-montserrat fs-40"
                  style={{ fontWeight: "700", color: "#000" }}
                >
                  Life at
                  <span style={{ color: "#5EBA3F", fontWeight: "700" }}>
                    NestaVera
                  </span>
                  !
                </h2>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/1.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/2.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/3.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/4.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/5.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/6.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/7.jpg" />
            </div>
            <div
              class="col-lg-4 col-md-6 pb-20"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img style={{ borderRadius: "30px" }} src="assets/about/8.jpg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
