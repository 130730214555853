let timelineElements = [
  {
    id: 1,
    title: "Kara Learning Pvt Ltd",
    description: "Kara Learning Pvt Ltd is located in Bangalore / Bengaluru is a top player in providing Preschool & Daycare education.",
    date: "April 2006",
    icon: "work"
  },
  {
    id: 2,
    title: "Greenwood High International School",
    description: "Greenwood High is one of the National Award Winning Best International Schools in Bangalore, Karnataka",
    date: "April 2006",
    icon: "work"
  },
  {
    id: 3,
    title: "DARSHITA EXIM PRIVATE LIMITED",
    description: "Darshita Exim Pvt. Ltd. actively running its operations in textile Mills / textile Product Mills",
    date: "Sept 2009",
    icon: "work"
  },
  {
    id: 4,
    title: "GREENWOODS EDU FACILITIES PRIVATE LIMITED",
    description: "Greenwoods Edu Facilities Private Limited is a Community, Personal & Social Services company and has headquarters in Bangalore",
    date: "Aug 2010",
    icon: "work"
  },
  {
    id: 5,
    title: "SATKRUTI EDUCATION MANAGEMENT PRIVATE LIMITED",
    description: "Satkruti education management private limited aspires to serve in COMMUNITY, PERSONAL and SOCIAL SERVICES activities across the India.",
    date: "Aug 2010",
    icon: "work"
  },
  {
    id: 6,
    title: "REARING EDUCATIONAL SERVICES",
    description: "Rearing Educational Services is majorly in Community, personal & Social Services business from last 11 years",
    date: "Aug 2011",
    icon: "work"
  },
  {
    id: 7,
    title: "NestaVera Education Management Pvt Ltd",
    description: "The major activities of this company as per the listed official records are Conduct of Graduate degree courses in engineering.",
    date: "Sept 2016",
    icon: "work"
  },
  {
    id: 8,
    title: "KI Education Foundation",
    description: "Key Education Foundation works with children from low-income communities to ensure that they get the best Early Childhood Education",
    date: "Jan 2020",
    icon: "work"
  },
  {
    id: 9,
    title: "SKY HIGH EDUCATIONAL FOUNDATION",
    description: "SKY High a 03-month online certification program on Emotional Well-being, Resilience and Self-Compassion under Project peaceful warrior",
    date: "July 2022",
    icon: "work"
  },
  {
    id: 10,
    title: "RISING EDUCATIONAL FOUNDATION",
    description: "Rising Education Foundation is an online platform launched in 2020, this initiative is all about the new layouts of law applications and norms.",
    date: "July 2022",
    icon: "work"
  },
];

export default timelineElements;
