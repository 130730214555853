import React from 'react'

export default function SocialMedia() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div class="" style={{ padding: '0px' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-7 col-lg-10 col-md-9 m-auto">
                            {/* <img src="https://via.placeholder.com/50x50" alt="" class="icon m-auto" /> */}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-7 col-lg-10 m-auto">
                            <div class=" mt-80 md-mt-50">
                                <div data-aos="zoom-out" class="item text-center" >
                                    <img src="assets/home/social.png" alt="" style={{ margin: 'auto', height: '450px' }} />
                                    {/* <div class='mt-10' style={{ lineHeight: 1.2 }}>
                                        <p class="fs-50" style={{ color: '#5EBA3F' }}>Check us out on <br />social media</p>
                                    </div> */}
                                </div>
                            </div>
                            {/* <img src="assets/home/Image-2.png" style={{ width: 50, left: 100, bottom: 140 }} alt="" class="shapes shape-one" />
                            <img src="assets/home/Image-1.png" style={{ width: 50, right: 10, bottom: 70 }} alt="" class="shapes shape-one" />
                            <img src="assets/home/Icon-2.png" style={{ width: 90, right: 70, bottom: 400 }} alt="" class="shapes shape-one" />
                            <img src="assets/home/Icon-1.png" style={{ width: 90, right: 120, bottom: 250 }} alt="" class="shapes shape-one" />
                            <img src="assets/home/Icon-4.png" style={{ width: 90, right: 150, top: 30 }} alt="" class="shapes shape-two" />
                            <img src="assets/home/Icon.png" style={{ width: 90, left: 150, top: 60 }} alt="" class="shapes shape-three" />
                            <img src="assets/home/Icon-3.png" style={{ width: 90, left: 110, bottom: 350 }} alt="" class="shapes shape-four" /> */}
                        </div>
                    </div>

                </div>
                <img src="assets/home/background1.png" alt="" style={{ marginTop: '-300px', zIndex: '10' }} />
            </div>

        </div>
    )
}
