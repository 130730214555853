import React, { useEffect, useState, useContext, } from "react";
import { GlobalContext } from '../../../context/GlobalState'
import parse from 'html-react-parser'
import './blogDetail.css'
import { useParams, useHistory } from 'react-router-dom';
import { localRoute } from '../../../routes/localRoutes';
// import { getBlog } from '../../../context/actions/blogAction/getBlog'
// import { getBlogs } from "../../../context/actions/blogAction/getBlogs";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Helmet } from "react-helmet";
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "../../../axios";
import BlogCard from "../blogCard/BlogCard";

export default function BlogDetails() {
    const { blogState, blogDispatch } = useContext(GlobalContext);
    const [blogDetail, setBlogDetail] = useState({})
    const [relatedBlogs, setRelatedBlogs] = useState([])
    const [filterBlogDetails, setFilterBlogDetails] = React.useState([]);
    const [tabFilter, setTabFilter] = React.useState('active');

    const history = useHistory()
    const { blog_id } = useParams()



    let pageTitlePath = history.location && history.location.pathname

    let newpath = "https://taskmo.com" + pageTitlePath

    const OpenCareer = () => {
        history.push(`${localRoute.career}`)
    };
    console.log("newpath", newpath)

    useEffect(() => {
        axios
            .get(`/articles/${blog_id}/`)
            .then((res) => {
                setBlogDetail(res.data.blog)
                setRelatedBlogs(res.data.related)
            })
            .catch((e) => {
                console.error(e);
            });
    }, [blog_id])



    const OpenBlog = (blog_id) => {
        history.push(`${localRoute.blogDetail}/${blog_id}`)
    }

    const handleGoBlogs = () => {
        history.push(`${localRoute.blogs}`);
    };

    console.log('blogDetail', blogDetail)
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {blogDetail.title && parse(blogDetail.title)}
                </title>
                <meta name="description" content={blogDetail.body} />
                <meta name="keywords" content={blogDetail.blog_meta_tag} />
                <link rel="canonical" href={newpath} />
            </Helmet>

            {/* <!--====== Page Title Area End ======--> */}

            {/* <!--====== Blog Area Started ======--> */}
        
            <section class="blog-area">
                <div class="single-blog-post blog-details-content">
                    <div class="post-thumbnail">
                        <img src={`${process.env.REACT_APP_API_HOST}${blogDetail.cover}`} alt={blogDetail.title} />
                    </div>
                    <div class="post-content">
                        <div class='font-montserrat fw-500' style={{ listStyleType: "initial" }}>
                            <h2 class="post-title font-montserrat fw-700">
                                {blogDetail.title}
                                {/* <a class="font-montserrat fw-600">
                                </a> */}
                            </h2>

                            {blogDetail.body && parse(blogDetail.body)}
                        </div>
                    </div>
                </div>

                <div class="font-montserrat feature-blog-one blog-page-bg container">
                    <div class="section-heading mt-50 my-50 pb-40">
                        <h2 class="font-montserrat fs-35 fw-700">What to read next</h2>
                    </div>

                    <div class="container">
                        <div class="row">
                            {relatedBlogs && relatedBlogs.map((blog) => (
                                <BlogCard key={blog.id} blog={blog} openBlog={() => OpenBlog(blog.id)} />
                            ))}
                            <div
                                class="col-lg-4 col-md-6"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                <div
                                    class="post-meta"
                                    style={{ background: "#1C1C1C", textAlign: "center" }}
                                >
                                    <h3 style={{ paddingTop: "150px" }}>
                                        <a
                                            style={{ color: "#fff" }}
                                            class="font-montserrat title fw-600"
                                        >
                                            Blogs & Articles with New updates
                                        </a>
                                    </h3>
                                    <Button
                                        variant="contained"
                                        style={{ background: "#fff", color: "#000", marginTop: 20 }}
                                        onClick={handleGoBlogs}
                                        endIcon={<ArrowForwardIosIcon />}
                                    >
                                        Discover All
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" data-aos="zoom-out">
                    {/* <div class='col-lg-6 m-auto text-center'>
                        <h4 class="fs-50 font-montserrat fw-700">
                            Join our team to be a part of our story
                        </h4>
                        <p class='font-montserrat fs-22 mt-20 fw-400' style={{ color: '#6D6E76' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                        </p>
                        <Button size="large" style={{ background: '#5EBA3F', color: '#fff', fontWeight: '700', margin: '20px 10px 30px 10px', padding: '10px 25px 10px 25px' }} onClick={OpenCareer}>
                            Join Now
                        </Button>
                    </div> */}
                </div>
            </section>
        </div>
    )
}
