import React from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import TimeChart from "./TimeChart/TimeChart";
import SubFooter from "components/subFooter";

export default function About() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img data-aos="zoom-in-up" src="assets/real-estate/main.png" alt="" />
          <div class="font-montserrat sliderBanner">
            <p
              class="font-montserrat mb-40 fw-200 fs-30"
              style={{ textAlign: "center" }}
            >
              LEADING REAL ESTATE INVESTMENTS TO SHAPE CITIES OF TOMORROW
            </p>
            <p
              class="font-montserrat fs-25 fw-300"
              style={{ textAlign: "center" }}
            >
              INDIA &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; SINGAPORE
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; DUBAI
            </p>
          </div>
        </div>
        <div>
          <div class="container">
            <div class="row pt-90 pb-90 sm-pt-30">
              <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
                Real Estate
              </div>

              <div class="col-lg-3 ml-auto order-lg-first mt-30">
                <span class="fw-600" style={{ color: "#1C1C1C" }}>
                  — — Real Estate at its best
                </span>
                <h2
                  style={{ cursor: "pointer", color: "#1C1C1C" }}
                  class="m-auto fw-800 fs-50 font-montserrat"
                >
                  Get the NestaVera Edge on Real Estate
                </h2>
              </div>
              <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
                <div class="text-wrapper">
                  <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    Real estate holds a special prominence in our diversified
                    portfolio. We have collaborations and ventures with major
                    residential and commercial spaces players that have in their
                    credit an array of landmark projects across the nation,
                    including homes, retail spaces, and IT parks. We are also
                    focusing on building a strong technology-based real estate
                    platform for our customer base to enable them a place where
                    they could modify the spaces based on their specific needs.
                    We are also strongly eyeing real estate presence in
                    collaborations and joint ventures in other countries.
                  </p>
                  <p class="fw-400 fs-16 mt-30" style={{ color: "#1C1C1C" }}>
                    With the rise in lifestyle expenses, the presence of an
                    international location and unique technology-enabled modern
                    spaces will allow them to suit their personal needs better.
                    Our collaboration and joint ventures with some of the
                    biggest real-estate players will symbolize our customer
                    base's trust and confidence. With the best spaces, brand
                    names, and brand edge technology, we will give you the
                    NestaVera Edge in your real estate journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "#1C1C1C" }}>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/india.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                INDIA
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Experience the luxury of well thought residential and corporate
                spaces and get a unique experience of Indian properties backed
                by NestaVera.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 order-lg-last order-md-last row-padd0">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/singapore.png"
                alt=""
                style={{ filter: "brightness(0.3)", padding: "0px" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                SINGAPORE
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Make Singapore your preferred destination and enjoy a unique
                experience in the choicest of NestaVera-approved properties.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <img
                data-aos="zoom-in-up"
                src="assets/real-estate/dubai.png"
                alt=""
                style={{ filter: "brightness(0.3)" }}
              />
              <div
                class="fs-50 fw-300"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  color: "#ddd",
                  transform: "translate(-50%, -50%)",
                  border: "2px solid #fff",
                  padding: "0px 30px 0px 30px",
                }}
              >
                DUBAI
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              class="col-lg-6 col-md-6 col-sm-12"
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <p class="bBoxPara">
                Indulge in the state of art infrastructure of Dubai's real
                estate and carve your desired life with the best of properties
                backed by NestaVera.
              </p>
            </div>
          </div>
        </div>

        <TimeChart />

        <SubFooter />
      </div>
    </>
  );
}
