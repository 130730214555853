import React from "react";
import "./FeaturedBlogCard.css";
import moment from 'moment';

export default function BlogCard({ blog, openBlog }) {
  return (
    <div class="container" data-aos="fade-up" data-aos-duration="1200">
      <div class="post-meta row" style={{ padding: '20px 20px 20px 5px' }}>
        <div class="col-lg-4 col-md-6">
          <img src={`${process.env.REACT_APP_API_HOST}${blog.cover}`} alt={blog.title} class="image-meta" onClick={openBlog} />
        </div>
        <div class="col-lg-8 col-md-6" style={{ display: 'block' }}>
          <p style={{ color: '#1C1C1C' }} class="fs-20 font-montserrat ">{blog.category_display} | {moment(blog?.updated_at).format('MMMM Do YYYY')}</p>
          <h3 class='mt-10'>
            <a
              style={{ cursor: "pointer" }} class="font-montserrat title fw-700"
              onClick={openBlog}
            >
              {blog.title}
            </a>
          </h3>
          <h4 class='font-montserrat fw-400 mt-10' style={{ color: '#6D6E76', cursor: "pointer" }} onClick={openBlog}>
            {blog.short_body}
          </h4>
          {/* redirect if external link */}
          {
            blog.external_link && (
              <a
                style={{ cursor: "pointer", color: '#4C4C4C', marginTop: '20px' }}
                class="read-more d-flex justify-content-between align-items-center"
                href={blog.external_link}
                target="_blank"
              >
                <span>

                </span>
                {/* <i class="flaticon-right-arrow"></i> */}
              </a>
            ) || (
              <a
                style={{ cursor: "pointer", marginTop: '20px' }}
                class="read-more d-flex justify-content-between align-items-center"
                onClick={openBlog}
              >
                <span>

                </span>
                {/* <i class="flaticon-right-arrow"></i> */}
              </a>
            )
          }
        </div>
      </div>
    </div>
  );
}
