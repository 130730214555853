import React from "react";
import validation from "../../validationBusiness";
import { useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { GlobalContext } from "context/GlobalState";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { createSubscriber } from "context/actions/subscriberAction/addSubscriber";
import { Grid, TextField } from "@mui/material";
import { localRoute } from "routes/localRoutes";

const init = {
  subscriber_email: "",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export default function NewsLetter() {
  const history = useHistory();

  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);

  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };

  const handleClose = () => {
    setErrors(false);
    setState(init);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setErrors(validation(state));

    if (
      state.subscriber_email !== "" &&
      validateEmail(state.subscriber_email)
    ) {
      const postData = {
        email: state.subscriber_email,
      };
      setTimeout(() => {
        console.log("Create Subscriber Detail", postData);
        createSubscriber(postData)(subscriberDispatch);
        setOpen(true);
      }, 500);
    }
  };

  useEffect(() => {
    console.log("subscriberState", subscriberState);
    let status =
      subscriberState.createSubscriber &&
      subscriberState.createSubscriber.data &&
      subscriberState.createSubscriber.status;
    if (status == 201) {
      setState(init);
      setOpen(true);
      console.log("store", store);
      subscriberState.createSubscriber = {
        loading: false,
        error: null,
        data: null,
      };
    }
  }, [subscriberState]);

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "300px" }}>
          Form Successfully Submitted!
        </Alert>
      </Snackbar>
      <div class="fancy-short-banner-three mt-20 mb-100">
        <div class="container" data-aos="zoom-out">
          {/* <div class="bg-wrapper howitworks" style={{ background: '#1C1C1C' }}> */}
          <div class="col-lg-12">
            <div
              class="form-wrapper"
              style={{ textAlign: "center", marginBottom: "30px" }}
            >
              <h2 class="font-montserrat fs-50 fw-700">
                Let's start a strategic <br />
                partnership
              </h2>
            </div>
            <div
              class="form-wrapper"
              style={{ padding: "0px", textAlign: "center" }}
            >
              <TextField
                id="outlined-basic"
                label="Enter mobile number"
                variant="outlined"
                size="medium"
                style={{
                  marginTop: "20px",
                  boxShadow: "0px 5px 16px rgb(221 221 221)",
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TextField
                id="outlined-basic"
                label="Enter your subject"
                variant="outlined"
                size="medium"
                style={{
                  marginTop: "20px",
                  boxShadow: "0px 5px 16px rgb(221 221 221)",
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Button style={{ background: '#0E0E0E', color: '#fff', fontWeight: '600', padding: '15px 35px 15px 35px', marginTop: '20px', boxShadow: '0px 5px 16px rgb(221 221 221)' }}>
                                Contact Us
                            </Button> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <img
        class="green-line"
        src="assets/home/line2.png"
        alt=""
        style={{ margin: "auto" }}
        data-aos="flip-down"
        data-aos-duration="2000"
      />
    </>
  );
}
