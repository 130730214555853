import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FAQ1 from "../faq/FAQ1";
import FAQ2 from "../faq/FAQ2";
import FAQ3 from "../faq/FAQ3";
import FAQ4 from "../faq/FAQ4";
import ChatBob from "components/faq/chatBob";

export default function SubFooter() {
  return (
    <>
      <div>
        <div
          class="fancy-short-banner-three mt-100 mb-100"
          style={{ position: "relative" }}
        >
          <div class="container">
            <div
              class="bg-wrapper howitworks"
              style={{ background: "#5EBA3F" }}
            >
              <div class="col-lg-12">
                <div
                  class="form-wrapper"
                  style={{ textAlign: "center", marginBottom: "30px" }}
                >
                  <h2
                    class="font-montserrat fs-30 fw-900"
                    style={{ color: "#fff" }}
                  >
                    We promise that if you fit the bill it won't take long to
                    start our journey together.
                  </h2>
                </div>
                {/* <div class="form-wrapper mt-30" style={{ padding: '0px', textAlign: 'center' }}>
                    <Button size="large" onClick={handleBackContact} style={{ background: '#fff', color: '#000', fontWeight: '500', padding: '15px 35px 15px 35px' }}>
                        Explore Now
                    </Button>
                </div> */}
              </div>
              <img
                src="assets/explore1.png"
                style={{ position: "absolute", top: "160px", left: "120px" }}
              />
              <img
                src="assets/explore2.png"
                style={{ position: "absolute", top: "30px", right: "120px" }}
              />
              <img
                src="assets/explore3.png"
                style={{
                  position: "absolute",
                  top: "50px",
                  left: "120px",
                  width: "100px",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ background: "#1C1C1C", color: "#fff" }}>
          <div class="container mt-10 mb-30 pt-40 pb-40">
            <h2 class="font-montserrat title fs-25 fw-900 pt-20 pb-30 cl-white text-center">
              We speak with our powerfull statistics
            </h2>
            <div class="row">
              <div
                class="col-lg-6 col-sm-6 md-mt-50"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="text-center">
                  <h2 class="cl-white fs-50 fw-800 font-montserrat">200 CR</h2>
                  <p class="fs-18 fw-400 font-montserrat">
                    Value of Global Portfolio
                  </p>
                </div>

                <div class="text-center">
                  <h2 class="cl-white fs-50 fw-800 font-montserrat">
                    10+ Years
                  </h2>
                  <p class="fs-18 fw-400 font-montserrat">
                    Teams combined experience
                  </p>
                </div>

              </div>

              <div
                class="col-lg-6 col-sm-6 md-mt-50"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="text-center">
                  <h2 class="cl-white fs-50 fw-800 font-montserrat">
                    30+ Years
                  </h2>
                  <p class="fs-18 fw-400 font-montserrat">
                    Investment Experience
                  </p>
                </div>

                <div class="text-center">
                  <h2 class="cl-white fs-50 fw-800 font-montserrat">20%</h2>
                  <p class="fs-18 fw-400 font-montserrat">
                    Year on Year Growth
                  </p>
                </div>
                

              </div>

            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "white" }}
          class="font-montserrat fancy-feature-eighteen"
          id="feature"
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-9 m-auto mb-30">
                <div
                  class="text-center"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <h2 class="font-montserrat fw-700 fs-50">
                    Get acquinted with the process
                  </h2>
                </div>
              </div>
            </div>

            <ul
              class="row nav nav-tabs justify-content-between"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <li class="nav-item col-lg-3 col-sm-6">
                <div
                  class="team-member team-block-one mb-50"
                  data-toggle="tab"
                  href="#ft1"
                >
                  <div
                    class=" text-center"
                    style={{
                      border: "1px solid #DBE4E9",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      class="fs-22 name fw-800 font-montserrat"
                      style={{ float: "left", color: "#1c1c1c", position:"absolute" }}
                    >
                      01
                    </div>
                    <br />
                    {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 75, height: 75, margin: 'auto' }}
                    /> */}
                    <img
                      src="/assets/demo1.png"
                      style={{ width: 50, height: 45, margin: "auto" }}
                    />
                    <div
                      class="fs-18 mt-20 name fw-800 font-montserrat"
                      style={{ color: "#1c1c1c" }}
                    >
                      Sustainability
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item col-lg-3 col-sm-6">
                <div
                  class="team-member team-block-one mb-50"
                  data-toggle="tab"
                  href="#ft2"
                >
                  <div
                    class=" text-center"
                    style={{
                      border: "1px solid #DBE4E9",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      class="fs-22 name fw-800 font-montserrat"
                      style={{ float: "left", color: "#1c1c1c", position:"absolute" }}
                    >
                      02
                    </div>
                    <br />
                    {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 75, height: 75, margin: 'auto' }}
                    /> */}
                    <img
                      src="/assets/demo2.png"
                      style={{ width: 50, height: 45, margin: "auto" }}
                    />
                    <div
                      class="fs-18 mt-20 name fw-800 font-montserrat"
                      style={{ color: "#1c1c1c" }}
                    >
                      Resource Optimisation
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item col-lg-3 col-sm-6">
                <div
                  class="team-member team-block-one mb-50"
                  data-toggle="tab"
                  href="#ft3"
                >
                  <div
                    class=" text-center"
                    style={{
                      border: "1px solid #DBE4E9",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      class="fs-22 name fw-800 font-montserrat"
                      style={{ float: "left", color: "#1c1c1c", position:"absolute" }}
                    >
                      03
                    </div>
                    <br />
                    {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 75, height: 75, margin: 'auto' }}
                    /> */}
                    <img
                      src="/assets/demo3.png"
                      style={{ width: 50, height: 45, margin: "auto" }}
                    />
                    <div
                      class="fs-18 mt-20 name fw-800 font-montserrat"
                      style={{ color: "#1c1c1c" }}
                    >
                      Capital Safety
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item col-lg-3 col-sm-6">
                <div
                  class="team-member team-block-one mb-50"
                  data-toggle="tab"
                  href="#ft4"
                >
                  <div
                    class=" text-center"
                    style={{
                      border: "1px solid #DBE4E9",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      class="fs-22 name fw-800 font-montserrat"
                      style={{ float: "left", color: "#1c1c1c", position:"absolute" }}
                    >
                      04
                    </div>
                    <br />
                    {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 75, height: 75, margin: 'auto' }}
                    /> */}
                    <img
                      src="/assets/demo4.png"
                      style={{ width: 50, height: 45, margin: "auto" }}
                    />
                    <div
                      class="fs-18 mt-20 name fw-800 font-montserrat"
                      style={{ color: "#1c1c1c" }}
                    >
                      Impact Investing
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            class="tab-content container"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <div class="tab-pane active" id="ft1">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <FAQ1 />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <ChatBob />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ft2">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <FAQ2 />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <ChatBob />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ft3">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <FAQ3 />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <ChatBob />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ft4">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <FAQ4 />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <ChatBob />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
