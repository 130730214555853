import React, { useEffect } from 'react'
import OurPartners from '../ourPartners/index'
import Counter from '../counter/index'
import OurServices from '../ourServices/index'
import Testimonial from '../testimonial/index'
import NestaveraHero from '../nestaveraHero/index'
import News from '../news'
import ClientStory from '../clientStory/ClientStory'

import { Helmet } from 'react-helmet'
import Newsletter from '../newsletter'
import BusinessGrowth from '../businessGrowth'
import OurStoryTab from '../ourStoryTab'
import OurCoreValues from '../ourCoreValues'
import LatestBlog from '../latestBlog'
import Howwework from '../howwework'
import BusinessProposal from '../businessProposal'
import OurTeam from '../ourTeam'
import SocialMedia from '../socialMedia'
import OurAwards from '../ourAwards'
import HorizontalScroll from 'react-scroll-horizontal'
import { Grid } from '@mui/material'
import { WindowSharp } from '@mui/icons-material'
export default function Home() {
  const child = { width: `120em`, height: `700px` }
  const parent = { width: `120em`, height: `700px` }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div >
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>
            Nestavera
          </title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://nestavera.com/" />
        </Helmet> */}

        {/* Vertical Scroll Animation https://circleci.com/#advantage-speed */}
        {/* <NestaveraHero /> */}
        {/* <iframe src="http://localhost:3000/home.html" width={1000} height={1000}></iframe> */}

        <BusinessGrowth />
        <OurStoryTab />

        {/* Horizatal Scroll Animation https://cred.club/ */}

        <OurCoreValues />
        <Howwework />
        <BusinessProposal />
        <OurPartners />
        <Testimonial />
        <OurTeam />
        <OurAwards />
        <News />
        <LatestBlog />
        <Newsletter />
        <SocialMedia />
      </div>
    </>
  )
}

