import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ3() {
  const [expanded, setExpanded] = React.useState("panel8");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              How do you work with your companies post-investment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              "At our company, we believe that post-investment support is just
              as important as the initial investment itself. After working with
              a company to secure funding, we work closely with them to ensure
              their success. This includes providing ongoing support and
              guidance through regular check-ins and consultations with our team
              of experts. We also work with our companies to identify any areas
              for improvement and provide resources and assistance to help them
              reach their goals. We also help our companies connect with our
              network of industry professionals and resources, including
              potential partners, customers, and advisors. We believe that this
              support helps our companies grow and thrive in the long term.
              Ultimately, our goal is to be a trusted partner to our companies
              and work alongside them to help them reach their full potential."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What sort of information will i need to provide when looking for
              funding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              When looking for funding, you will need to provide information
              about your company and your business plan. This may include
              details about your company's history, management team, product or
              service offerings, target market, financial projections, and
              growth plans. You may also need to provide information about your
              current financial situation, including your revenue and expenses,
              and any outstanding debts or liabilities. Additionally, you may
              need to provide information about your current funding needs and
              how you plan to use the funding to support your business. Other
              information that may be required may include proof of ownership,
              business licenses, and any relevant legal documents.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          class="mt-10"
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          style={{
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            background: "none",
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography
              class="tTransmall fw-700 fs-22"
              sx={{ flexShrink: 0 }}
              style={{ color: "#170F49", marginBottom: "0" }}
            >
              What experience does the management have?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography class="fw-400 fs-18" style={{ color: "#6F6C90" }}>
              The management team at our company has a wealth of experience in
              the investment industry. Our team includes individuals with
              backgrounds in finance, economics, and business, who have worked
              at top financial institutions and investment firms. This
              experience has given them a deep understanding of the markets and
              the ability to make informed, strategic investment decisions. Our
              management team has a track record of success in maximizing
              returns for our clients, and we are confident in their ability to
              continue doing so in the future.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </div>
  );
}
