import React from "react";
import { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Find_Taskers_Common from "components/Find_Taskers_Common";
import Avatar from "@mui/material/Avatar";
import PageviewIcon from "@mui/icons-material/Pageview";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { localRoute } from "routes/localRoutes";
import Gallery from "./Gallery";

export default function About() {
  const history = useHistory();

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  let height = window.innerWidth;

  console.log("heightheight", height);

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img
            data-aos="zoom-in-up"
            data-aos-duration="3000"
            src="assets/about/team.jpg"
            alt=""
            style={{ filter: "brightness(0.9)" }}
          />
          <div
            class="font-montserrat text-on-image"
            style={{ position: "absolute", lineHeight: "1.5" }}
          >
            We build strong relationships between <br />
            companies and their growth.
          </div>
        </div>

        <div>
          <div class="container">
            <div class="row pt-90 pb-90 sm-pt-30">
              <div class="col-lg-2 ml-auto order-lg-first mt-30 fs-80 fw-700 horizontal-display-none">
                About Us
              </div>

              <div
                class="col-lg-3 ml-auto order-lg-first mt-30"
                data-aos="zoom-in-up"
              >
                <h2 class="m-auto fw-800 fs-50 font-montserrat">
                  Together we are very strong
                </h2>
              </div>
              <div data-aos="zoom-in-up" class="col-lg-7 order-lg-last ">
                <div class="text-wrapper">
                  <p
                    class="font-montserrat fs-16 fw-400 mt-30"
                    style={{ color: "#1C1C1C" }}
                  >
                    At NestaVera, we strongly believe in diversifying the
                    market. No matter how steady the market appears, it tends to
                    be volatile. Therefore, a broader approach to holding and
                    functioning a diversified portfolio is adapted to ensure
                    returns, profitability and economic and social value.
                    NestaVera not only aims to build businesses, which give
                    economic returns, but also offer collective growth and value
                    for society at large.
                  </p>
                  <p
                    class="font-montserrat fs-16 fw-400 mt-30"
                    style={{ color: "#1C1C1C" }}
                  >
                    Our name, in itself is an affirmation of our intent, for
                    Nesta means Pure and Vera means Faith. Our logo is the bird,
                    Goose, which is represented by the Latin word “Semper
                    Fidelis”, which means “always faithful” and “always loyal”.
                    We have chosen the Goose to represent us, as its symbolic
                    attributes of, loyalty, teamwork, confidence, protection,
                    determination, courage, inspiration, guidance and stability,
                    along with its ability to soar to greater heights, are in
                    accordance with our purpose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container mb-80">
          <div
            class="row"
            style={{ background: "#FFEFDA", padding: "50px" }}
            data-aos="zoom-in-up"
          >
            <div class="col-lg-6 order-lg-first">
              <div class="text-wrapper">
                <h2
                  class="m-auto fw-600 fs-16 font-montserrat"
                  style={{ color: "#000" }}
                >
                  OUR MISSION
                </h2>
                <p
                  class="mt-20 fw-500 font-montserrat"
                  style={{ fontSize: "16px" }}
                >
                  We aspire to actualise both economic and social development,
                  wealth and value, with an approach that is not only novel but
                  also backed by our core principles of Faith and Integrity.
                </p>
              </div>
            </div>
            <div class="col-lg-6 md-mt-50 col-md-8 ml-auto order-lg-last">
              <div class="text-wrapper">
                <h2
                  class="m-auto fw-600 fs-16 font-montserrat"
                  style={{ color: "#000" }}
                >
                  OUR VISION
                </h2>
                <p
                  class="mt-20 fw-500 font-montserrat"
                  style={{ fontSize: "16px" }}
                >
                  NestaVera represents a consortium of diverse companies that
                  seek business utility and profitability with the motive to
                  minimize risks and maximize returns and value in today's
                  modern dynamic business conditions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Gallery />
        
        <div style={{ background: "#001A2D" }}>
          <div class="container">
            <div class="row pt-90 pb-90">
              <div data-aos="zoom-in-up" class="col-lg-6 order-lg-first ">
                <div class="text-wrapper" style={{ color: "#fff" }}>
                  <h2
                    style={{ color: "#fff" }}
                    class="m-auto fw-700 fs-40 font-montserrat"
                  >
                    Looking to get funded by NestaVera?
                  </h2>
                  <p class="fw-400 mt-30" style={{ fontSize: "18px" }}>
                  Let's start a relationship between two commercial enterprises as it is always better to have strategic partners than doing it all yourself. As a strategic partner, we can help you scale your height and achieve success. We promise that if you fit the bill, it won't take long to start our journey together.
                  </p>
                  <br />
                  <hr style={{ background: "#fff" }} />
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleBackContact}
                    style={{ background: "#5EBA3F", marginTop: 20 }}
                  >
                    GET IN TOUCH
                  </Button>
                </div>
              </div>
              <div class="col-lg-6 col-md-8 ml-auto order-lg-last mt-30">
                <img
                  data-aos="zoom-in-up"
                  src="assets/about/about.jpg"
                  alt=""
                  style={{ filter: "brightness(0.9)" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="team-section-five">
          <div class="container">
            <h2 class="font-montserrat title fs-50 fw-700 text-center mb-40">
              Our Team
            </h2>
            <div class="row">
              <div
                class="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div
                  class="team-member team-block-one mb-50"
                  onclick="location.href='team-single.html';"
                >
                  <div class="info text-center">
                    <Avatar
                      alt="Remy Sharp"
                      src="/assets/home/ramji.jpeg"
                      sx={{ width: 125, height: 125, margin: "auto" }}
                    />
                    <div
                      class="name font-montserrat"
                      style={{ fontWeight: "700" }}
                    >
                      Late. Ramji Mehrotra
                    </div>
                    <div
                      class="desig font-montserrat"
                      style={{ color: "#6D6E76" }}
                    >
                      Chairman
                    </div>
                    <div class="address-info">
                      <ul class="d-flex justify-content-center">
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <div
                  class="team-member team-block-one mb-50"
                  onclick="location.href='team-single.html';"
                >
                  <div class="info text-center">
                    <Avatar
                      alt="Remy Sharp"
                      src="/assets/home/manas.jpeg"
                      sx={{ width: 125, height: 125, margin: "auto" }}
                    />
                    <div
                      class="name font-montserrat"
                      style={{ fontWeight: "700" }}
                    >
                      Manas Mehrotra
                    </div>
                    <div
                      class="desig font-montserrat"
                      style={{ color: "#6D6E76" }}
                    >
                      Managing Director
                    </div>
                    <div class="address-info">
                      <ul class="d-flex justify-content-center">
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400"
              >
                <div
                  class="team-member team-block-one mb-50"
                  onclick="location.href='team-single.html';"
                >
                  <div class="info text-center">
                    <Avatar
                      alt="Remy Sharp"
                      src="/assets/home/prachi.jpeg"
                      sx={{ width: 125, height: 125, margin: "auto" }}
                    />
                    <div
                      class="name font-montserrat"
                      style={{ fontWeight: "700" }}
                    >
                      Prachi Mehrotra
                    </div>
                    <div
                      class="desig font-montserrat"
                      style={{ color: "#6D6E76" }}
                    >
                      Director
                    </div>
                    <div class="address-info">
                      <ul class="d-flex justify-content-center">
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ margin: "5px 10px", color: "#232536" }}
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
