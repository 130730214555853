let Base_Url = `https://api-nestavera.ddsio.com`;
if (process.env.REACT_APP_NODE_ENV === "production") {
    Base_Url = `https://api-nestavera.ddsio.com`;
} else if (process.env.REACT_APP_NODE_ENV === "development") {
    Base_Url = `https://api-nestavera.ddsio.com`;
} else {
    Base_Url = `https://api-nestavera.ddsio.com`;
}


console.log(Base_Url)
export const createSubscriberApi = `${Base_Url}/job_application/`;

