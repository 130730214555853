import React from "react";
import { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Find_Taskers_Common from "components/Find_Taskers_Common";
import Avatar from "@mui/material/Avatar";
import PageviewIcon from "@mui/icons-material/Pageview";
import Button from "@mui/material/Button";
import { localRoute } from "routes/localRoutes";
import { useHistory } from "react-router-dom";

export default function CSR() {
  const history = useHistory();

  const handleBackContact = () => {
    history.push(localRoute.contact);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  let height = window.innerWidth;

  console.log("heightheight", height);

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>NestaVera</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://werkbiz.com/about-us" />
        </Helmet>

        <div class="maBanner">
          <img
            data-aos="zoom-in-up"
            data-aos-duration="3000"
            src="assets/csr/csr-main.jpg"
            alt=""
            style={{ filter: "brightness(0.5)" }}
          />
          <div
            class="font-montserrat text-on-image"
            style={{ position: "absolute", lineHeight: "1.5" }}
          >
            Socially Responsible to Empower <br/>
            Communities & Enrich Lives
            {/* We build strong relationships between <br />
                        companies and their growth. */}
          </div>
        </div>

        <div>
          <div class="container mt-50 mb-100">
            <div class="row">
              <div
                data-aos="zoom-in-up"
                class="col-lg-12"
                style={{ borderLeft: "8px solid #FFD050", borderRadius: "5px" }}
              >
                <div class="text-wrapper">
                  <p
                    class="font-montserrat fs-22 fw-500"
                    style={{ color: "#5D5B5B" }}
                  >
                    Social responsibility is something that is a part of our
                    mission statement as a company and very much a part of how
                    we think and who we are. Together we can use the power of
                    our brand to promote positive values and each of us can
                    contribute to making this world a better place and have a
                    sustainable impact in the communities where we live and
                    work.
                  </p>
                  <h2
                    class="font-montserrat fs-24 fw-700 mt-20"
                    style={{ color: "#5EBA3F" }}
                  >
                    Late. Ramji Mehrotra
                  </h2>
                  <h4 class="font-montserrat fs-16 fw-700 mt-10">Founder</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="container mt-50">
            <h2 class="font-montserrat title fs-50 fw-700 text-center">
              Our <span style={{ color: "#FD6A5E" }}>Values</span>
            </h2>
            <div class="row pt-70 sm-pt-40">
              <div data-aos="zoom-in-up" class="col-lg-6 order-lg-first ">
                <div class="text-wrapper">
                  <h2 class="m-auto fw-700 fs-40 font-montserrat">
                    Community Development
                  </h2>
                  <p
                    class="fw-700 mt-30 font-montserrat"
                    style={{ color: "#232536", fontSize: "18px" }}
                  >
                    At NestaVera, we believe that Businesses can do more than just
                    make money. We believe that business can change the world,
                    and we're committed to doing our part.
                  </p>
                  <p
                    class="fw-400 mt-30 font-montserrat"
                    style={{ fontSize: "16px" }}
                  >
                    We're a community development organization that provides
                    funding and support to organizations working on issues
                    related to social change, environmental sustainability, and
                    economic development. We've been working with our partner
                    organizations since 2007 to help them meet their goals of
                    increasing access to education, improving health care
                    outcomes for local communities, and fostering economic
                    growth in their regions—and we are excited about the impact
                    we can make together!
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-8 ml-auto order-lg-last">
                <img data-aos="zoom-in-up" src="assets/csr/kmi.jpg" alt="" />
              </div>
            </div>
            <div class="row pt-90 pb-50">
              <div class="col-lg-6 col-md-8 ml-auto order-lg-first">
                <img data-aos="zoom-in-up" src="assets/csr/girl.jpg" alt="" />
              </div>
              <div data-aos="zoom-in-up" class="col-lg-6 order-lg-last ">
                <div class="text-wrapper">
                  <h2 class="fw-700 fs-40 font-montserrat sm-mt-40">We Care</h2>
                  <p
                    class="fw-700 mt-30 font-montserrat"
                    style={{ color: "#232536", fontSize: "18px" }}
                  >
                    Our Community Development team works hard to support our
                    communities by solving issues, creating opportunities,
                    providing resources, and building relationships between
                    organizations.
                  </p>
                  <p
                    class="fw-400 mt-30 font-montserrat"
                    style={{ fontSize: "16px" }}
                  >
                    Community Development is the process of creating a community
                    through the efforts of individuals, organizations, and
                    businesses. It involves working with people to solve
                    problems in your community and make it better for everyone
                    who lives there. At NestaVera, we believe that everyone
                    deserves to live in a safe and healthy community. That's why
                    we strive to create environments where people feel
                    comfortable and welcome, whether it's through our work with
                    the homeless or by helping local businesses boost their
                    bottom line.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="fancy-feature-thirtyTwo pt-50 pb-60 md-pt-50 sm-pt-10 md-pb-60 rGallery">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div
                class="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div class="title-style-eleven mb-30">
                  <h2
                    class="font-montserrat fs-40"
                    style={{ fontWeight: "700" }}
                  >
                    Our <br />{" "}
                    <span style={{ color: "#5EBA3F", fontWeight: "700" }}>
                      Activities
                    </span>{" "}
                    !
                  </h2>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img alt="about" src="assets/about/1.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img alt="about" src="assets/about/2.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img alt="about" src="assets/about/3.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img alt="about" src="assets/about/4.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img alt="about" src="assets/about/5.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img alt="about" src="assets/about/6.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img alt="about" src="assets/about/7.jpg" />
              </div>
              <div
                class="col-lg-4 col-md-6 pb-20"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img alt="about" src="assets/about/8.jpg" />
              </div>
            </div>
          </div>
        </div>

        {/* <div class="fancy-short-banner-three mb-80">
                    <div class="container" >
                        <div class="bg-wrapper howitworks" style={{ background: '#1C1C1C' }} data-aos="zoom-in-up" data-aos-duration="2000">
                            <div class="col-lg-12">
                                <div class="form-wrapper" style={{ textAlign: 'center', marginBottom: '30px' }}>
                                    <h2 class="font-montserrat" style={{ color: '#fff', fontSize: '40px' }}>
                                    Let's start with something good for your Business
                                    </h2>
                                </div>
                                <div class="form-wrapper" style={{ padding: '0px', textAlign: 'center' }}>
                                    <Button size="large" style={{ background: '#fff', color: '#000', fontWeight: '600', padding: '10px 25px 10px 25px' }} onClick={handleBackContact}>
                                        Contact Us 
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
}
