import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import { mainContaint } from "./constant";
import "./NestaveraHero.css";
import { Container } from "@mui/material";

gsap.registerPlugin(ScrollTrigger);

export default function OurStoryTab() {
    const [index, setIndex] = useState(0);
    const panels = useRef([]);
    const panelsContainer = useRef();

    // const createPanelsRefs = (panel, index) => {
    // 	panels.current[index] = panel;
    // };

    // const handleBackContact = () => {
    //     if (index === 2) {
    //         setIndex(0)
    //     } else {
    //         setIndex(index + 1);
    //     }
    // }
    // useEffect(() => {
    // 	const totalPanels = panels.current.length;
    // 	gsap.to(panels.current, {
    // 		xPercent: -100 * (totalPanels - 1),
    // 		ease: "none",
    // 		// scrollTo: { y: i * innerHeight, autoKill: false },
    // 		scrollTrigger: {
    // 			trigger: panelsContainer.current,
    // 			pin: true,
    // 			scrub: true,
    // 			// invalidateOnRefresh: true,
    // 			anticipatePin: 1,
    // 			snap: 1 / (totalPanels - 1),

    // 			// invalidateOnRefresh: true,
    // 			// base vertical scrolling on how wide the container is so it feels more natural.
    // 			end: () => "+=" + panelsContainer.current.offsetWidth
    // 		}
    // 	});

    // 	return () => {
    // 		ScrollTrigger.getAll().forEach((frame) => frame.kill());
    // 	};
    // }, []);

    useEffect(() => {

        ScrollTrigger.defaults({
            markers: false
        })

        var points = gsap.utils.toArray('.point');
        var indicators = gsap.utils.toArray('.indicator');

        var height = 100 * points.length;

        gsap.set('.indicators', { display: "flex" });

        var tl = gsap.timeline({
            duration: points.length,
            scrollTrigger: {
                trigger: ".philosophie",
                start: "top center",
                end: "+=" + height + "%",
                scrub: true,
                id: "points",
            }
        })

        var pinner = gsap.timeline({
            scrollTrigger: {
                trigger: ".philosophie .wrapper",
                start: "top top",
                end: "+=" + height + "%",
                scrub: true,
                pin: ".philosophie .wrapper",
                pinSpacing: true,
                id: "pinning",
                // markers: true
            }
        })



        points.forEach(function (elem, i) {
            gsap.set(elem, { position: "absolute", top: 0 });

            tl.to(indicators[i], { backgroundColor: "orange", duration: 0.25 }, i)
            tl.from(elem.querySelector('img'), { autoAlpha: 0 }, i)
            tl.from(elem.querySelector('article'), { autoAlpha: 0, translateY: 100 }, i)

            if (i !== points.length - 1) {
                tl.to(indicators[i], { backgroundColor: "#adadad", duration: 0.25 }, i + 0.75)
                tl.to(elem.querySelector('article'), { autoAlpha: 0, translateY: -100 }, i + 0.75)
                tl.to(elem.querySelector('img'), { autoAlpha: 0 }, i + 0.75)
            }
        });

        // return () => {
        //     ScrollTrigger.getAll().forEach((frame) => frame.kill());
        // };
    }, []);

    return (
        <section class="philosophie">
            <div class="wrapper">
                {/* <div class="indicators">
					<div class="indicator"></div>
					<div class="indicator"></div>
					<div class="indicator"></div>
					<div class="indicator"></div>
					<div class="indicator"></div>
				</div> */}
                <div className="content-wrap">

                    <div class="point">
                        <img src='assets/home/ourstory1.png' alt="random" width="500" height="500" />
                        <article className="ml-5 mr-4">
                            {/* <p>{mainContaint[0].text}</p>
                            <h3>{mainContaint[0].title}</h3> */}
                            <div class="cl-white">
                                <div style={{ margin: '0px 60px 0px 0px' }}>
                                    <h3 class="font-montserrat fw-800 cl-white mt-10 fs-40" style={{ lineHeight: "1em", padding: "10px", border: '5px solid #fff' }}>1943</h3>
                                </div>
                                <h3 class="font-montserrat fw-700 cl-white mt-10 fs-30">
                                    Formation of the Company
                                </h3>
                                <span class="font-montserrat fw-500 cl-white mt-10 fs-20" style={{ margin: '0px 0px 0px 60px' }}>
                                    We Strongly believe in diversity for the market
                                </span>
                            </div>
                        </article>
                    </div>
                    <div class="point">
                        <img src='assets/home/ourstory1.png' alt="random" width="500" height="500" />
                        <article className="ml-5 mr-4">
                            <div class="cl-white">
                                <div style={{ margin: '0px 60px 0px 0px' }}>
                                    <h3 class="font-montserrat fw-800 cl-white mt-10 fs-40" style={{ lineHeight: "1em", padding: "10px", border: '5px solid #fff' }}>1979</h3>
                                </div>
                                <h3 class="font-montserrat fw-700 cl-white mt-10 fs-30">
                                    Formation of the Company
                                </h3>
                                <span class="font-montserrat fw-500 cl-white mt-10 fs-20" style={{ margin: '0px 0px 0px 60px' }}>
                                    We Strongly believe in diversity for the market
                                </span>
                            </div>
                        </article>
                    </div>
                    <div class="point">
                        <img src='assets/home/ourstory1.png' alt="random" width="500" height="500" />
                        <article className="ml-5 mr-4">
                            <div class="cl-white">
                                <div style={{ margin: '0px 60px 0px 0px' }}>
                                    <h3 class="font-montserrat fw-800 cl-white mt-10 fs-40" style={{ lineHeight: "1em", padding: "10px", border: '5px solid #fff' }}>2011</h3>
                                </div>
                                <h3 class="font-montserrat fw-700 cl-white mt-10 fs-30">
                                    Formation of the Company
                                </h3>
                                <span class="font-montserrat fw-500 cl-white mt-10 fs-20" style={{ margin: '0px 0px 0px 60px' }}>
                                    We Strongly believe in diversity for the market
                                </span>
                            </div>
                        </article>
                    </div>
                </div>
                {/* <div class="point">
                    <article className="ml-5 mr-4">
                        <p>{mainContaint[3].text}</p>
                        <h3>{mainContaint[3].title}</h3>
                    </article>
                    <img src={mainContaint[3].imgUrl} alt="random" width="500" height="500" />
                </div>
                <div class="point">
                    <article className="ml-5 mr-4">
                        <p>{mainContaint[4].text}</p>
                        <h3>{mainContaint[4].title}</h3>
                    </article>
                    <img src={mainContaint[4].imgUrl} alt="random" width="500" height="500" />
                </div> */}
            </div >
        </section >

    );
}