import React from 'react'
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import timelineElements from "./timelineElements";
import "./TimeChart.css";
import {
    VerticalTimeline,
    VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";


export default function TimeChart() {
    let workIconStyles = { background: "#fff", width: '40px', height: '40px' };
    let schoolIconStyles = { background: "#fff", width: '40px', height: '40px' };
    return (
        <div style={{ background: '#1e1e1e' }}>
            <VerticalTimeline
                animate={true}
            >
                {timelineElements.map((element) => {
                    let isWorkIcon = element.icon === "work";
                    let showButton =
                        element.buttonText !== undefined &&
                        element.buttonText !== null &&
                        element.buttonText !== "";
                    return (
                        // <div data-aos="zoom-in-up">
                        <VerticalTimelineElement
                            key={element.key}
                            date={element.date}
                            dateClassName="font-montserrat"
                            iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                            icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                        >

                            {/* <h3 className="vertical-timeline-element-title font-montserrat">
                                {element.title}
                            </h3> */}
                            <h5 className="vertical-timeline-element-subtitle font-montserrat" style={{ color: '#000' }}>
                                {element.title}
                            </h5>
                            <p class='font-montserrat' id="description">
                                {element.description}
                            </p>
                        </VerticalTimelineElement>
                        // {/* </div> */ }
                    );
                })}
            </VerticalTimeline>

        </div>
    )
}
