import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Testimonial() {

    const handlePreview = () => {

    }
    const handleNext = () => {

    }
    const slider = React.useRef(null);

    var settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 3500,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>

            <div class="bg-wrapper">
                <div class="container">
                    <div class="section-heading mt-100">
                        <h2 class="font-montserrat fs-60 fw-700 text-center">What Our <br /><span style={{ color: '#FCC75F' }}>Clients</span> Says</h2>
                    </div>
                    <div class="title-style-four mt-30">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 m-auto" >
                                <Slider ref={slider} {...settings}>
                                    <div class="" >
                                        <div class="col-lg-12 row" style={{ padding: '50px' }}  >
                                            <img src='assets/home/client-border.png' alt='' style={{ maxHeight: '320px', position: 'absolute', margin: '-20px' }} />

                                            <div class="col-lg-8 first-half-a-border-on" >
                                                <div class="first-half-a-border-on-top">
                                                    <p class='font-montserrat mt-30 fw-700 fs-25' style={{ color: '#130000' }}>The service was amazing.
                                                        I never had to wait for my food.The service was amazing.
                                                        I never had to wait for my food.</p>

                                                    <div class='mt-10' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src='assets/home/client-icons.png' alt='' style={{ width: '80px', height: '50px' }} />
                                                        <div style={{ marginLeft: '20px' }}>
                                                            <p class='fw-700 font-montserrat fs-22 mt-10' style={{ color: '#000', lineHeight: 1 }}>Rubinder Singh</p>
                                                            <span class='font-montserrat fw-400' style={{ color: '#000' }}>Pro Physio</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-4 half-a-border-on" style={{ border: '7px solid #5EBA3F', borderLeft: 0 }}>
                                                <div class="">
                                                    <img src='assets/home/Bitmap.png' alt='' style={{ width: '300px', padding: '10px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" >
                                        <div class="col-lg-12 row" style={{ padding: '50px' }} >
                                            <img src='assets/home/client-border.png' alt='' style={{ maxHeight: '320px', position: 'absolute', margin: '-20px' }} />

                                            <div class="col-lg-8 first-half-a-border-on" >
                                                <div class="first-half-a-border-on-top">
                                                    <p class='font-montserrat mt-50 fw-700' style={{ color: '#130000' }}>I had the privilege of partnering with NestaVera. They understood my business and always had insightful input that helped me grow in my career. I'm so happy to have found a firm that is not only backing me financially but also providing guidance and resources to make sure I succeed!</p>

                                                    <div class='mt-10' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src='assets/home/client-icons.png' alt='' style={{ width: '80px', height: '50px' }} />
                                                        <div style={{ marginLeft: '20px' }}>
                                                            <p class='fw-700 font-montserrat fs-22 mt-10' style={{ color: '#000', lineHeight: 1 }}>Smita Puri</p>
                                                            <span class='font-montserrat fw-400' style={{ color: '#000' }}>Goodbox</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 half-a-border-on" style={{ border: '7px solid #5EBA3F', borderLeft: 0 }}>
                                                <div class="">
                                                    <img src='assets/home/Bitmap.png' alt='' style={{ width: '300px', padding: '10px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" >
                                        <div class="col-lg-12 row" style={{ padding: '50px' }} >
                                            <img src='assets/home/client-border.png' alt='' style={{ maxHeight: '340px', position: 'absolute', margin: '-10px' }} />
                                            <div class="col-lg-8 first-half-a-border-on" >
                                                <div class="first-half-a-border-on-top">
                                                    <p class='font-montserrat mt-50 fw-700' style={{ color: '#130000' }}>I've been working with NestaVera for about 2 years now and I can't say enough about the team. They have been instrumental in helping me grow my business and have a great track record in growing their clients. It's not every VC you can work with that will back you up 100% and help you to achieve your goals.</p>
                                                    <div class='mt-10' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src='assets/home/client-icons.png' alt='' style={{ width: '80px', height: '50px' }} />
                                                        <div style={{ marginLeft: '20px' }}>
                                                            <p class='fw-700 font-montserrat fs-22 mt-10' style={{ color: '#000', lineHeight: 1 }}>Vedika Shah</p>
                                                            <span class='font-montserrat fw-400' style={{ color: '#000' }}>Neuron-18 Technology</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 half-a-border-on" style={{ border: '7px solid #5EBA3F', borderLeft: 0 }}>
                                                <div class="">
                                                    <img src='assets/home/Bitmap.png' alt='' style={{ width: '300px', padding: '10px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>

                                <div style={{ display: 'flex', margin: '10px 20px' }}>
                                    <button class="theme-btn-nine2" style={{ padding: '10px 15px', borderRadius: '50%', border: '2px solid #4EAA23' }} onClick={() => slider?.current?.slickPrev()}>
                                        <ArrowBackIcon />
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button class="theme-btn-nine2" style={{ padding: '10px 15px', borderRadius: '50%', border: '2px solid #4EAA23' }} onClick={() => slider?.current?.slickNext()}>
                                        <ArrowForwardIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <img class="green-line" src="assets/home/line.png" alt="" style={{ margin: 'auto' }} /> */}
                    {/* <img class="green-line" src="assets/home/line.png" alt="" style={{ margin: 'auto' }} data-aos="flip-down" data-aos-duration="2000" /> */}
                </div>
            </div >
        </>
    );
}
